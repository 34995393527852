<template>
	<div class="page-wrapper">
		<div class="payment-view">
			<header class="header">
				<div class="header-left" @click="$router.push({ path: '/index' })">
					<img src="../assets/images/icons/textAndlogo.png" class="logo" />
				</div>
				<div class="header-right">
					<span class="goback color-blue fs-15" @click="goBack">返回</span>
				</div>
			</header>
			<div class="top">
				<h1 class="title">收银台</h1>
			</div>
			<div class="product">
				<div class="product-info text-center">
					<span class="name">{{ productInfo.productName }}</span>
					<span class="month">{{ productInfo.packageName }}</span>
					<span class="p-l-10 font-bold fs-25"
						>数量x{{ productInfo.orderMember && productInfo.orderMember.length }}</span
					>
				</div>
				<div class="pay-money color-brown text-center m-t-10">￥{{ productInfo.price }}</div>
			</div>
			<div class="codes">
				<i class="handle-code-icon"></i>
				<div class="code-box" v-for="(item, index) in payModes" :key="index">
					<template v-if="item.payTypeEnums == 'webchatPay'">
						<el-card class="box-card code-img-box">
							<img :src="webchatPayCodeUrl" class="code-img" /> <i class="wechat"></i>
							<div class="flex jc-center">
								<i class="sao-1-sao"></i>
								<div class="tx-lf">
									<p>打开手机微信</p>
									<p>扫一扫继续付款</p>
								</div>
							</div>
						</el-card>
					</template>
					<template v-if="item.payTypeEnums == 'alipay'">
						<el-card class="box-card code-img-box">
							<iframe :srcdoc="item.codeUrl" class="code-img" frameborder="no" border="0" scrolling="no"></iframe>
							<div class="flex jc-center ai-center m-t-15">
								<i class="sao-1-sao"></i>
								<div class="tx-lf">
									<p>打开手机支付宝</p>
									<p>扫一扫继续付款</p>
								</div>
							</div>
						</el-card>
					</template>
				</div>
			</div>
		</div>
		<common-footer></common-footer>
	</div>
</template>
<script>
import QRCode from 'qrcode'
import { queryOrderStatus } from '@/config/api'
export default {
	name: 'PaymentView',
	data() {
		return {
			payModes: [],
			webchatPayCodeUrl: '',
			productInfo: {},
			clearId: null,
		}
	},
	computed: {},
	methods: {
		jumpHash(name) {
			if (name === 'index') {
				this.$router.push({ path: '/index' })
			} else if (name === 'product') {
				this.$router.push({ path: '/index', query: { to: 'product' } })
			} else if (name === 'aboutUs') {
				this.$router.push({ path: '/index', query: { to: 'aboutus' } })
			} else if (name === 'download') {
				this.$router.push({ path: '/downloadFile' })
			}
		},
		goBack() {
			this.$router.push({ path: '/buyNow' })
		},
		loopQueryOrderStatus() {
			this.clearId = setInterval(() => {
				const params = {
					outTradeNo: localStorage.getItem('outTradeNo'),
				}
				queryOrderStatus(params).then(res => {
					if (res.result) {
						this.$router.push({ path: 'paymentSuc' })
					} else {
						//this.$message.error('支付失败')
					}
				})
			}, 1000)
		},
	},
	mounted() {
		try {
			const productInfo = JSON.parse(localStorage.getItem('productInfo')) || {}
			this.productInfo = productInfo
			const orderCreateResponses = JSON.parse(localStorage.getItem('orderCreateResponses'))
			this.payModes = orderCreateResponses || []
			this.payModes.forEach(item => {
				if (item.payTypeEnums === 'webchatPay') {
					QRCode.toDataURL(item.codeUrl)
						.then(url => {
							console.log(url)
							this.webchatPayCodeUrl = url
						})
						.catch(err => {
							console.error(err)
						})
				}
			})
		} catch (error) {
			console.log(error)
			this.payModes = []
		}
		this.loopQueryOrderStatus()
	},
	beforeDestroy() {
		clearInterval(this.clearId)
	},
}
</script>
<style lang="scss" scoped>
.page-wrapper {
	background: linear-gradient(180deg, #acdeff 0%, #dff7ff 52%, #ffffff 100%);
	width: 100%;
	height: 100%;
}
.payment-view {
	width: 1220px;
	margin: 0 auto;
	margin-bottom: 80px;

	.header {
		height: 60px;
		padding: 0 30px;
		display: flex;
		justify-content: space-between;
		.header-left {
			height: 60px;
			display: flex;
			align-items: center;
			.logo {
				width: 180px;
				height: 22px;
				margin-right: 5px;
			}
		}
		.header-right {
			height: 60px;
			display: flex;
			align-items: center;
			width: 60px;
			text-align: center;
			justify-content: center;
		}
	}
	.top {
		height: 40px;
		line-height: 40px;
		display: flex;
		justify-content: space-between;
		margin-top: 50px;
		.title {
			font-size: 42px;
		}
		.back {
			font-size: 20px;
			cursor: pointer;
			text-decoration: underline;
		}
	}
	.product {
		padding-top: 30px;
		.name {
			font-size: 28px;
			font-weight: bold;
			padding-right: 10px;
		}
		.month {
			display: inline-block;
			font-size: 28px;
			font-weight: bold;
			&::before {
				width: 1px;
				height: 30px;
				background-color: #999;
				content: '';
				display: inline-block;
				vertical-align: middle;
				margin-right: 10px;
			}
			&::after {
				width: 1px;
				height: 30px;
				background-color: #999;
				content: '';
				display: inline-block;
				vertical-align: middle;
				margin-left: 10px;
			}
		}
		.pay-money {
			font-size: 40px;
			font-weight: bold;
			margin-bottom: 50px;
		}
	}
	.codes {
		width: 550px;
		display: flex;
		justify-content: space-around;
		margin: 0 auto;
		margin-top: 20px;
		position: relative;
		.code-img {
			width: 150px;
			height: 150px;
			margin-top: 20px;
		}
		.code-box {
			text-align: center;
		}

		.sao-1-sao {
			display: inline-block;
			width: 35px;
			height: 35px;
			background: url(../assets/images/icons/sao-icon.png) no-repeat;
			background-size: contain;
			margin-right: 10px;
		}
		.wechat {
			width: 38px;
			height: 38px;
			display: inline-block;
			vertical-align: middle;
			background: url(../assets/images/icons/wechat.png) no-repeat;
			background-size: contain;
			position: absolute;
			top: 110px;
			left: 86px;
		}
		.alipay {
			width: 30px;
			height: 30px;
			display: inline-block;
			vertical-align: middle;
			background-size: contain;
			background: url(../assets/images/icons/alipay.png) no-repeat;
			background-size: contain;
			margin-right: 5px;
		}
	}
	.code-img-box {
		position: relative;
		border-radius: 18px;
		height: 270px;
		width: 200px;
	}
	.handle-code-icon {
		width: 260px;
		height: 260px;
		position: absolute;
		top: -100px;
		right: -250px;
		background: url(../assets/images/icons/handle-code.png) center center no-repeat;
		background-size: 180px 200px;
		border-radius: 50%;
		border: 1px solid #999;
	}
}
</style>
<style></style>
