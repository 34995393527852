import Vue from 'vue'
import ElementUI from 'element-ui'
import VueClipboard from 'vue-clipboard2'
import 'element-ui/lib/theme-chalk/index.css'
import App from './App.vue'
import router from './router'
import store from './store'
import './assets/scss/base.scss'
import util from './config/util'
import './lib/comparison/zx-comparison.css'
import Header from '@/views/common/Header'
import Footer from '@/views/common/Footer'
import CopyRight from '@/views/common/CopyRight.vue'

Vue.use(ElementUI)
VueClipboard.config.autoSetContainer = true // add this line
Vue.use(VueClipboard)
Vue.config.productionTip = false

Vue.prototype.$util = util

Vue.component('common-header', Header)
Vue.component('common-footer', Footer)
Vue.component('copy-right', CopyRight)
new Vue({
	router,
	store,
	render: h => h(App),
}).$mount('#app')
