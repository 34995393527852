<template>
	<div class="edit-user-modal">
		<el-dialog title="修改密码" :visible.sync="dialogVisible" width="30%" :close-on-click-modal="false">
			<el-form :model="ruleForm" ref="ruleForm" label-width="100px" class="rule-form">
				<el-form-item prop="nickName" label="姓名"
					><p>{{ propsData.nickName }}</p>
				</el-form-item>
				<el-form-item prop="userName" label="账号"
					><p>{{ propsData.account }}</p>
				</el-form-item>
				<el-form-item
					prop="password"
					label="密码"
					:rules="[{ required: true, validator: validatePass, trigger: 'blur' }]"
				>
					<el-input
						type="password"
						v-model="ruleForm.password"
						autocomplete="off"
						placeholder="包含字母、数字或符号，至少2种， 不少于8位"
						:maxLength="32"
					></el-input>
				</el-form-item>
				<el-form-item
					prop="confirmPassword"
					label="确认密码"
					:rules="[{ required: true, validator: validateConfirmPass, trigger: 'blur' }]"
				>
					<el-input
						type="password"
						v-model="ruleForm.confirmPassword"
						autocomplete="off"
						placeholder="包含字母、数字或符号，至少2种， 不少于8位"
						:maxLength="32"
					></el-input>
				</el-form-item>
			</el-form>
			<span slot="footer" class="dialog-footer">
				<el-button @click="handleCancel">取 消</el-button>
				<el-button type="primary" @click="submitForm">确 定</el-button>
			</span>
		</el-dialog>
	</div>
</template>
<script>
import { changeUserTeamPassword } from '@/config/api.js'
export default {
	name: 'ModifyPassword',
	props: {
		propsData: {
			type: Object,
			default: () => {
				return {}
			},
		},
	},
	data() {
		return {
			dialogVisible: false,
			ruleForm: {},
			formName: 'ruleForm',
		}
	},
	methods: {
		show() {
			this.dialogVisible = true
		},
		close() {
			this.dialogVisible = false
		},
		validatePass(rule, value, callback) {
			console.log('value', value)
			if (!value) {
				callback(new Error('请输入密码'))
			} else if (!this.$util.validPswdComplex(value)) {
				callback(new Error('包含字母、数字或符号，至少2种， 不少于8位'))
			} else {
				if (this.ruleForm.confirmPassword !== '') {
					this.$refs.ruleForm.validateField('confirmPassword')
				}
				callback()
			}
		},
		validateConfirmPass(rule, value, callback) {
			if (!value) {
				callback(new Error('请再次输入密码'))
			} else if (!this.$util.validPswdComplex(value)) {
				callback(new Error('包含字母、数字或符号，至少2种， 不少于8位'))
			} else if (value !== this.ruleForm.password) {
				callback(new Error('两次输入密码不一致!'))
			} else {
				callback()
			}
		},
		handleCancel() {
			this.dialogVisible = false
			this.resetForm()
		},
		submitForm() {
			this.$refs[this.formName].validate(valid => {
				if (valid) {
					const params = {
						account: this.propsData.account,
						...this.ruleForm,
					}
					this.$loading()
					changeUserTeamPassword(params).then(res => {
						console.log('res', res)
						this.$loading().close()
						this.$message.success('修改成功')
						this.handleCancel()
						this.$emit('finish')
					})
				} else {
					console.log('error submit!!')
					return false
				}
			})
		},
		resetForm() {
			this.$refs[this.formName].resetFields()
		},
	},
}
</script>
