import Index from '../views/Index.vue'
import BuyNow from '../views/BuyNow.vue'
import Login from '../views/Login.vue'
import Register from '../views/Register.vue'
import Layout from '../components/Layout.vue'
import UserInfo from '../views/UserInfo.vue'
import Address from '../views/Address.vue'
import Order from '../views/Order.vue'
import Payment from '../views/Payment.vue'
import PrivacyPolicy from '../views/PrivacyPolicy.vue'
import UserContract from '../views/UserContract.vue'

import OrderList from '../views/OrderList.vue'
import MyTeam from '../views/MyTeam.vue'
import Invoice from '../views/Invoice.vue'
import Download from '../views/Download.vue'
import updateLog from '../views/updateLog.vue'
import InvoiceHeader from '../views/InvoiceHeader.vue'
import PaymentSuc from '../views/PaymentSuc.vue'
const routes = [
	{
		path: '/',
		redirect: '/index',
	},
	{
		path: '/index',
		name: 'index',
		component: Index,
		meta: { title: '首页' },
	},
	{
		path: '/banner',
		name: 'banner',
		component: Index,
		meta: { title: '首页' },
	},
	{
		path: '/product-list',
		name: 'product',
		component: Index,
		meta: { title: '首页' },
	},
	{
		path: '/aboutUs',
		name: 'aboutUs',
		component: Index,
		meta: { title: '首页' },
	},
	{
		path: '/about',
		name: 'about',
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () => import(/* webpackChunkName: 'about' */ '../views/AboutView.vue'),
	},
	{
		path: '/login',
		name: 'login',
		component: Login,
		meta: { title: '登录' },
	},
	{
		path: '/register',
		name: 'register',
		component: Register,
		meta: { title: '注册' },
	},
	{
		path: '/buyNow',
		name: 'BuyNow',
		component: BuyNow,
		meta: { title: '立即购买' },
	},
	{
		path: '/order',
		name: 'Order',
		component: Order,
		meta: { title: '下单', requiresAuth: true, keepAlive: true },
	},
	{
		path: '/payment',
		name: 'Payment',
		component: Payment,
		meta: { title: '支付', requiresAuth: true },
	},

	{
		path: '/paymentSuc',
		name: 'PaymentSuc',
		component: PaymentSuc,
		meta: { title: '支付成功', requiresAuth: true },
	},

	{
		path: '/privacyPolicy',
		name: 'PrivacyPolicy',
		component: PrivacyPolicy,
		meta: { title: '协议', keepAlive: true },
	},
	{
		path: '/userContract',
		name: 'UserContract',
		component: UserContract,
		meta: { title: '协议', keepAlive: true },
	},
	{
		path: '/downloadFile',
		name: 'Download',
		component: Download,
		meta: { title: '软件下载' },
	},
	{
		path: '/updateLog',
		name: 'UpdateLog',
		component: updateLog,
		meta: { title: '更新日志' },
	},
	{
		path: '/forgotPswd',
		name: 'ForgotPswd',
		meta: { title: '忘记密码' },
		component: () => import(/* webpackChunkName: 'ForgotPswd' */ '../views/ForgotPswd.vue'),
	},
	{
		path: '/checkProcess',
		name: 'CheckProcess',
		meta: { title: '忘记密码' },
		component: () => import(/* webpackChunkName: 'ForgotPswd' */ '../views/CheckProcess.vue'),
	},
	{
		path: '/layout',
		name: 'Layout',
		component: Layout,
		children: [
			{
				path: '/layout/userInfo',
				name: 'userInfo',
				component: UserInfo,
				meta: { title: '用户资料', requiresAuth: true },
			},
			{
				path: '/layout/orderList',
				name: 'OrderList',
				component: OrderList,
				meta: { title: '我的订单', requiresAuth: true },
			},
			{
				path: '/layout/myTeam',
				name: 'MyTeam',
				component: MyTeam,
				meta: { title: '我的团队', requiresAuth: true },
			},
			{
				path: '/layout/address',
				name: 'Address',
				component: Address,
				meta: { title: '收货地址', requiresAuth: true },
			},
			{
				path: '/layout/invoice',
				name: 'Invoice',
				component: Invoice,
				meta: { title: '我的发票', requiresAuth: true },
			},
			{
				path: '/layout/invoiceHeader',
				name: 'InvoiceHeader',
				component: InvoiceHeader,
				meta: { title: '发票抬头', requiresAuth: true },
			},
		],
	},
]

export default routes
