export default {
	toSecretPhone(phoneNum) {
		console.log(phoneNum)
		if (!phoneNum) return ''
		const str = phoneNum.substring(0, 3) + '*****' + phoneNum.substring(7, 11)
		return str
	},
	validPswdComplex(password) {
		const reg = /((?=.*\d)(?=.*\D)|(?=.*[a-zA-Z])(?=.*[^a-zA-Z]))^.{8,16}/
		return reg.test(password)
	},
	validUserAccount(str) {
		const reg = /^[0-9a-zA-Z]{1,32}$/
		return reg.test(str)
	},
	downloadFile(fileName, bytes) {
		console.log(fileName.replaceAll('"', ''))
		fileName = fileName.replaceAll('"', '')
		console.log('fileName', fileName)
		const type = fileName.split('.')[1]
		const fileTypeMap = {
			doc: 'application/vnd.msword;charset=utf-8',
			docx: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
			xls: 'application/vnd.ms-excel',
			xlsx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
			pdf: 'application/pdf',
			zip: 'application/zip',
		}

		let blob = new Blob([bytes], {
			type: fileTypeMap[type], //word文档为msword,pdf文档为pdf
		})
		let objectUrl = URL.createObjectURL(blob)
		var tempLink = document.createElement('a')
		tempLink.style.display = 'none'
		tempLink.href = objectUrl
		tempLink.setAttribute('download', fileName)
		if (typeof tempLink.download === 'undefined') {
			tempLink.setAttribute('target', '_blank')
		}
		document.body.appendChild(tempLink)
		tempLink.click()
		document.body.removeChild(tempLink)
		window.URL.revokeObjectURL(objectUrl)
	},
}
