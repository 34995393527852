<template>
	<div class="register-view">
		<header class="header">
			<div class="header-left pointer" @click="$router.push({ path: '/index' })">
				<img src="../assets/images/icons/textAndlogo.png" class="logo" />
			</div>
		</header>
		<div class="register-box">
			<el-card class="box-card">
				<div class="login-container">
					<div class="text-center">
						<img class="logo-icon" src="../assets/images/icons/textAndlogo.png" />
					</div>
					<div class="login-form" :class="{ pt10: errorMsg }">
						<div class="error" v-show="errorMsg">
							<span>{{ errorMsg }}</span>
							<!-- <span v-show="!errorMsg">{{ errorMsg2 }}</span> -->
						</div>
						<div class="form-item">
							<span class="fs-12 font-bold label">企业名称</span>
							<el-input class="input-text" v-model="form.nickName" placeholder="请输入"></el-input>
						</div>
						<div class="form-item m-t-10">
							<span class="fs-12 font-bold label">设置密码</span>
							<el-input
								class="input-text"
								v-model="form.password"
								placeholder="字母、数字或符号，至少2种， 不少于8位"
								show-password
								:maxLength="32"
								@blur="checkPasswordComplex"
							></el-input>
						</div>
						<div class="form-item m-t-10">
							<span class="fs-12 font-bold label">确认密码</span>
							<el-input
								class="input-text"
								v-model="form.confirmPassword"
								placeholder="字母、数字或符号，至少2种， 不少于8位"
								show-password
								:maxLength="32"
								@blur="checkPasswordComplex"
							></el-input>
						</div>
						<template v-if="type === 'tel'">
							<div class="form-item m-t-10">
								<span class="fs-12 font-bold label">验证手机</span>
								<el-input
									class="input-text"
									v-model="form.contact"
									placeholder="请输入"
									@blur="checkUserExist"
								></el-input>
								<!-- <span class="fs-10 m-l-5 set-absolute">或者<a @click="changeTo('email')"> 邮箱验证 </a></span> -->
							</div>
							<div class="form-item m-t-10">
								<Verify
									@success="success"
									@error="error"
									mode="pop"
									captchaType="blockPuzzle"
									:imgSize="{ width: '330px', height: '155px' }"
									ref="verify"
								></Verify>
								<span class="fs-12 font-bold label">短信验证码</span>
								<el-input class="input-text input-code" v-model="form.code" placeholder="请输入"></el-input>
								<el-button
									class="send-btn"
									size="small"
									:class="{ 'disable-btn': !canSend }"
									:disabled="!canSend"
									@click="sendSMSCode('tel')"
									>{{ sendBtnText }}</el-button
								>
							</div>
						</template>
						<template v-if="type === 'email'">
							<div class="form-item m-t-10">
								<span class="fs-12 font-bold label">验证邮箱</span>
								<el-input
									class="input-text"
									v-model="form.contact"
									placeholder="请输入"
									@blur="checkUserExist"
								></el-input>
								<span class="fs-10 m-l-5 set-absolute">或者<a @click="changeTo('tel')"> 手机验证 </a></span>
							</div>
							<div class="form-item m-t-10">
								<span class="fs-12 font-bold label">邮箱验证码</span>
								<el-input class="input-text input-code" v-model="form.code" placeholder="请输入"></el-input>
								<el-button class="send-btn" size="small" @click="sendSMSCode('email')">发送至邮箱</el-button>
							</div>
						</template>
						<div class="form-item m-t-10">
							<span class="fs-12 font-bold label">验证码</span>
							<el-input class="input-text input-code" v-model="form.imgCode" placeholder="请输入"></el-input>
							<span class="img-code-span" @click="changeImgCode"
								><img class="img-code" :src="imgCodeSrc" /><a class="fs-10">看不清？</a></span
							>
						</div>
						<div class="password-set">
							<el-checkbox class="check-box" v-model="checked"></el-checkbox>
							<span class="fs-10 forger-pswd"
								>我已阅读并接受<router-link to="/userContract">用户协议</router-link>和<router-link to="/privacyPolicy"
									>版权协议</router-link
								></span
							>
						</div>
						<div class="login-btn-box">
							<el-button class="login-btn" type="primary" @click="register">注 册</el-button>
						</div>
					</div>
					<div class="create-account">
						<span class="fs-12">已有账户？</span
						><span><router-link :to="{ path: '/login', query: $route.query }">直接登录</router-link></span>
					</div>
				</div>
				<img class="login-img" src="../assets/images/login-img.png" />
			</el-card>
		</div>
		<copy-right></copy-right>
	</div>
</template>
<script>
import { captcha, register, sendVeriCode, checkUserExists, checkAndSendCode } from '@/config/api.js'
import Verify from '@/components/verifition/Verify.vue'
export default {
	name: 'LoginView',
	components: {
		Verify,
	},
	data() {
		return {
			form: {},
			checked: undefined,
			errorMsg: '',
			isExists: false,
			type: 'tel',
			imgCodeSrc: '',
			canSend: true,
			sendBtnText: '发送验证码',
			count: 60,
			contactUUid: '',
			imgUUid: '',
			userNameIsExist: false,
			passwordComplex: true,
			inputCheck: true,
			errorMsgList: [],
			showImg: false,
		}
	},
	methods: {
		changeTo(type) {
			this.errorMsg = ''
			this.type = type
		},
		success(params) {
			// params 返回的二次验证参数, 和登录参数一起回传给登录接口，方便后台进行二次验证
			console.log('params', params)
			const paramss = {
				address: this.form.contact,
				data: {
					captchaType: 'blockPuzzle',
					pointJson: params.pointJson,
					token: params.token,
					captchaVerification: params.captchaVerification,
				},
				module: 0,
			}
			checkAndSendCode(paramss).then(res => {
				if (res.checkResult && res.res.filedValue) {
					this.contactUUid = res.res.filedValue
					this.countDown()
				}
			})
		},
		error() {
			console.log('匹配失败')
		},
		checkUserExist() {
			if (this.form.contact) {
				checkUserExists({ userName: this.form.contact }).then(res => {
					if (res) {
						this.errorMsg = '该手机号已被注册，请更换手机号再试'
						this.userNameIsExist = true
						this.errorMsgList.push({
							type: 'exist',
							errorMsg: this.errorMsg,
						})
					} else {
						this.userNameIsExist = false
						this.errorMsg = ''
						this.errorMsgList = this.errorMsgList.filter(item => {
							item.type !== 'exist'
						})
					}
				})
			}
		},
		changeImgCode() {
			captcha().then(res => {
				const data = res.base64EncoderImg
				this.imgCodeSrc = data
				this.imgUUid = res.filedValue
			})
		},
		checkPasswordComplex() {
			const reg = /((?=.*\d)(?=.*\D)|(?=.*[a-zA-Z])(?=.*[^a-zA-Z]))^.{8,16}/
			if (this.form.password && !reg.test(this.form.password)) {
				this.errorMsg = '包含字母、数字或符号，至少2种， 不少于8位'
				this.passwordComplex = false
				this.errorMsgList.push({
					type: 'complex',
					errorMsg: this.errorMsg,
				})
				return
			}
			if (this.form.confirmPassword && !reg.test(this.form.confirmPassword)) {
				this.errorMsg = '包含字母、数字或符号，至少2种， 不少于8位'
				this.passwordComplex = false
				this.errorMsgList.push({
					type: 'complex',
					errorMsg: this.errorMsg,
				})
				return
			}
			if (this.form.confirmPassword && this.form.password && this.form.confirmPassword !== this.form.password) {
				this.errorMsg = '设置密码和确认密码不一致'
				this.passwordComplex = false
				this.errorMsgList.push({
					type: 'complex',
					errorMsg: this.errorMsg,
				})
				return
			}
			this.passwordComplex = true
			this.errorMsgList = this.errorMsgList.filter(item => {
				return item.type !== 'complex'
			})
		},
		registerCheck() {
			let checkList = null
			if (this.type === 'tel') {
				checkList = {
					nickName: '请输入姓名',
					password: '请设置密码',
					confirmPassword: '请输入确认密码',
					contact: '请输入验证手机号',
					code: '请输入短信验证码',
					imgCode: '请输入验证码',
				}
			} else if (this.type === 'email') {
				checkList = {
					nickName: '请输入姓名',
					password: '请设置密码',
					confirmPassword: '请输入确认密码',
					contact: '请输入邮箱',
					code: '请输入邮箱验证码',
					imgCode: '请输入验证码',
				}
			}
			let passFlag = true
			const objKeys = Object.keys(checkList)
			for (let i = 0; i < objKeys.length; i++) {
				if (!this.form[objKeys[i]]) {
					this.errorMsg = checkList[objKeys[i]]
					this.errorMsgList.push({
						type: 'input',
						errorMsg: this.errorMsg,
					})
					passFlag = false
					break
				}
			}
			if (this.type === 'tel') {
				const regular = /^1[3456789]\d{9}$/
				if (!regular.test(this.form.contact)) {
					this.errorMsg = '手机号格式不正确'
					this.errorMsgList.push({
						type: 'input',
						errorMsg: this.errorMsg,
					})
					passFlag = false
				}
			} else if (this.type === 'email') {
				if (!this.form.contact.includes('@')) {
					this.errorMsg = '邮箱格式不正确'
					this.errorMsgList.push({
						type: 'input',
						errorMsg: this.errorMsg,
					})
					passFlag = false
				}
			}
			if (passFlag) {
				this.inputCheck = true
				this.errorMsgList = this.errorMsgList.filter(item => {
					return item.type !== 'input'
				})
			} else {
				this.inputCheck = false
			}
		},
		countDown() {
			clearInterval(this.clearId)
			this.count = 60
			this.sendBtnText = this.count + ' 秒后重发'
			this.canSend = false
			this.clearId = setInterval(() => {
				this.count--
				if (this.count > 0) {
					this.sendBtnText = this.count + ' 秒后重发'
				} else {
					this.canSend = true
					this.sendBtnText = '发送验证码'
				}
			}, 1000)
		},
		sendSMSCode(type) {
			if (type === 'tel') {
				const regular = /^1[3456789]\d{9}$/
				if (!this.form.contact) {
					this.errorMsg = '请输入验证手机号'
					return
				}
				if (!regular.test(this.form.contact)) {
					this.errorMsg = '手机号格式不正确'
					return
				}
				if (sessionStorage.getItem('canSend') === 'false') {
					this.showImg = true
					this.$refs.verify.show()
					return
				}
			} else {
				if (!this.form.contact) {
					this.errorMsg = '请输入邮箱'
					return
				}
			}
			const params = {
				address: this.form.contact,
				module: 0,
			}
			sendVeriCode(params).then(res => {
				this.smsfiledName = res.filedName
				this.contactUUid = res.filedValue
				console.log('res', res)
				if (type === 'tel') {
					if (res.showImage) {
						sessionStorage.setItem('canSend', false)
					}
					// 倒计时
					this.countDown()
				} else {
					this.$message.success('发送成功')
				}
			})
		},
		checkAll() {
			this.checkPasswordComplex()
			this.registerCheck()
		},
		register() {
			this.checkAll()
			if (this.userNameIsExist || !this.inputCheck || !this.passwordComplex) {
				console.log(this.errorMsgList)
				this.errorMsg = this.errorMsgList[0].errorMsg
				return
			}
			this.errorMsg = ''
			if (!this.checked) {
				this.$message.error('请同意协议')
				return
			}
			console.log('check pass')
			this.$loading()
			const params = {
				...this.form,
				contactUUid: this.contactUUid,
				imgUUid: this.imgUUid,
			}
			register(params).then(
				res => {
					this.$loading().close()
					console.log('res', res)
					this.$message.success('注册成功')
					this.$router.push({ path: '/login', query: this.$route.query })
				},
				() => {
					this.$loading().close()
				}
			)
		},
	},
	mounted() {
		this.changeImgCode()
	},
}
</script>
<style lang="scss" scoped>
.register-view {
	width: 100%;
	height: 100vh;
	background: url(../assets/images/banner-bg.png) no-repeat;
	background-size: 100%;
}

.register-box {
	display: flex;
	justify-content: center;
	align-items: center;
	height: calc(100vh - 60px);
}
.header {
	height: 60px;
	padding: 0 30px;
	display: flex;
	justify-content: space-between;
	width: 1225px;
	margin: 0 auto;
	.header-left {
		height: 60px;
		display: flex;
		align-items: center;
		.logo {
			width: 180px;
			height: 22px;
		}
	}
	.header-right {
		height: 60px;
		display: flex;
		align-items: center;
		width: 60px;
		text-align: center;
		justify-content: center;
	}
}
.box-card {
	// width: 750px;
	height: 550px;
	overflow: hidden;
	display: flex;
}
.login-container {
	flex: 1;
	height: 100%;
	padding-top: 30px;
	.logo-icon {
		width: 180px;
		height: 22px;
	}
}

.disable-btn {
	background-color: #fdfdfd;
	color: #c6c6c6;
}

.error {
	width: 256px;
	height: 25px;
	line-height: 25px;
	background-color: #fef2f2;
	border-color: #ffb4a8;
	border: 1px solid #ff934c;
	color: #6c6c6c;
	font-size: 12px;
	padding-left: 10px;
	margin-bottom: 10px;
	margin-left: auto;
	margin-right: auto;
}
.login-form {
	padding: 25px 30px 20px 30px;
}
.input-text {
	display: inline-block;
	width: 210px;
}
.password-set {
	padding-top: 20px;
	display: flex;
	align-items: center;
	justify-content: center;
}
.forger-pswd {
	padding-right: 15px;
	cursor: pointer;
	margin-left: 5px;
	a {
		padding: 0 3px;
	}
}
.login-btn-box {
	margin-top: 15px;
	text-align: center;
}
.login-btn {
	width: 160px;
	background: url(../assets/images/login-btn-bg.png) no-repeat;
	background-size: 100%;
	border: none;
}
.login-img {
	display: block;
	height: 100%;
	// width: 45%;
}
.create-account {
	text-align: center;
	font-size: 10px;
	a {
		text-decoration: underline;
		cursor: pointer;
	}
}
.pt10 {
	padding-top: 10px;
}
.label {
	width: 80px;
	text-align: right;
	padding-right: 10px;
	display: inline-block;
}
.img-code {
	width: 80px;
	height: 30px;
	display: inline-block;
	vertical-align: middle;
	margin-left: 5px;
}
.input-code {
	width: 110px;
	position: relative;
}
.send-btn {
	width: 85px;
	font-size: 10px;
	margin-left: 5px;
}
.set-absolute {
	position: absolute;
	margin-top: 7px;
}
</style>
<style>
.register-view .el-card__body {
	width: 900px;
	padding: 0;
	display: flex;
}
.register-view .el-input__inner {
	height: 30px;
	line-height: 30px;
	padding: 0 10px;
}
.register-view .el-checkbox__inner {
	width: 12px;
	height: 12px;
}
.register-view .el-checkbox__label {
	font-size: 10px;
}
.register-view .copy-right {
	position: absolute;
	bottom: 0;
	left: 0;
}
</style>
