<template>
	<div class="footer">
		<div class="footer-list">
			<div class="footer-item">
				<img src="../../assets/images/icons/logo.png" class="footer-icon" />
			</div>
			<div class="footer-item">
				<p class="color-black font-bold fs-18">公司</p>
				<template v-if="fromPage === 'index'">
					<p class="color-black fs-15 m-t-10 pointer">
						<el-link @click="jumpHash('aboutUs')">关于翌兽</el-link>
					</p>
				</template>
				<template v-else>
					<p class="color-black fs-15 m-t-10 pointer">
						<el-link @click="jumpHash('aboutUs')">关于翌兽</el-link>
					</p>
				</template>
			</div>
			<div class="footer-item">
				<p class="color-black font-bold fs-18">产品特征</p>
				<p class="color-black fs-15 m-t-10 pointer" v-for="(name, index) in productData" :key="index">
					<el-link @click="jumpHash('product')">{{ name }}</el-link>
				</p>
			</div>
			<div class="footer-item">
				<p class="color-black font-bold fs-18">技术与支持</p>
				<p class="color-black fs-15 m-t-10 pointer"><el-link @click="jumpHash('download')">软件下载</el-link></p>
				<p class="color-black fs-15 m-t-10 pointer"><el-link @click="jumpHash('download')">知识库</el-link></p>
			</div>
			<div class="footer-item" style="width: 225px">
				<p class="color-black font-bold fs-18">联系我们</p>
				<p class="color-black fs-15 m-t-10" v-for="e in telData" :key="e.id">联系电话：{{ e.title }}</p>
				<p class="color-black fs-15 m-t-10" v-for="e in emailData" :key="e.id">{{ e.title }}</p>
				<p class="color-black fs-15 m-t-10 pointer">
					<template v-for="p in qrCodeData">
						<el-popover placement="top-start" width="100" popper-class="img-popper" trigger="click" :key="p.id">
							<img :src="p.pngUr" class="hover-img" />
							<img class="wechat" slot="reference" :src="p.icon" />
						</el-popover>
					</template>
				</p>
			</div>
		</div>
		<copy-right></copy-right>
	</div>
</template>
<script>
import { findAboutUs, findIndexProduct } from '@/config/api'
export default {
	name: 'CommonFooter',
	props: {
		fromPage: {
			type: String,
			default: '',
		},
	},
	data() {
		return {
			emailData: [],
			qrCodeData: [],
			telData: [],
			productData: [],
		}
	},
	methods: {
		fromIndex(name) {
			if (name === 'banner') {
				window.location.hash = '#banner'
			} else if (name === 'product') {
				window.location.hash = '#product-list'
			} else if (name === 'aboutUs') {
				window.location.hash = '#aboutUs'
			} else if (name === 'download') {
				this.$router.push({ path: '/downloadFile' })
			}
		},
		fromOther(name) {
			if (name === 'index') {
				this.$router.push({ path: '/index' })
			} else if (name === 'product') {
				this.$router.push({ path: '/index', query: { to: 'product' } })
			} else if (name === 'aboutUs') {
				this.$router.push({ path: '/index', query: { to: 'aboutus' } })
			} else if (name === 'download') {
				this.$router.push({ path: '/downloadFile' })
			}
		},
		jumpHash(name) {
			if (this.fromPage === 'index') {
				this.fromIndex(name)
			} else {
				this.fromOther(name)
			}
		},
		getProductData() {
			findIndexProduct().then(res => {
				this.productData = (res || []).map(item => {
					return item.productName
				})
			})
		},
	},
	mounted() {
		findAboutUs().then(res => {
			const list = res || []
			list.forEach(item => {
				if (item.type === 'email') {
					this.emailData = item.aboutUsRes || []
				} else if (item.type === 'qr_code') {
					this.qrCodeData = item.aboutUsRes || []
				} else if (item.type === 'tel') {
					this.telData = item.aboutUsRes || []
				}
			})
		})
		this.getProductData()
	},
}
</script>
<style lang="scss" scoped>
.footer {
	border-top: 1px solid #eee;
	height: auto !important;
	background-color: #fff;

	.footer-list {
		display: flex;
		justify-content: space-around;
		padding-top: 30px;
		margin-top: 15px;
	}
	.footer-item {
		width: 180px;
	}
	.wechat {
		width: 30px;
		height: 25px;
	}
	.footer-icon {
		width: 118px;
	}
}
</style>
<style>
.hover-img {
	width: 100px;
	height: 100px;
	margin: 0 auto;
	display: block;
}
.img-popper {
	min-width: 100px;
}
.copy-right {
	text-align: center;
	font-size: 12px;
	color: #9195a3;
	display: flex;
	justify-content: center;
	align-items: center;
	border-top: 1px solid #eee;
	padding-top: 20px;
}
.gov-link {
	color: #9195a3;
	font-size: 12px;
}
.gov-link:hover {
	color: #333;
}
.split-line {
	font-size: 12px;
	padding: 0 10px;
	color: #ccc;
}
</style>
