<template>
	<el-container class="download-page">
		<common-header fromPage="download"></common-header>
		<div class="page-wrap">
			<el-main class="main">
				<el-card class="box-card" style="min-height: 500px">
					<div slot="header" class="clearfix">
						<span class="text-bold fs-25">更新日志</span>
					</div>
					<el-table :data="tableData" style="width: 100%">
						<el-table-column prop="docTitle" label="标题" width="180"> </el-table-column>
						<el-table-column prop="fileName" label="内容">
							<template slot-scope="scope">
								<div v-html="scope.row.fileName"></div>
							</template>
						</el-table-column>
						<!-- 2024-01-06 隐藏 -->
						<!-- <el-table-column prop="updateTime" label="更新时间"> </el-table-column> -->
					</el-table>
					<div class="text-right m-t-10">
						<el-pagination
							@size-change="handleSizeChange"
							@current-change="handleCurrentChange"
							:current-page.sync="pageNum"
							:page-size="pageSize"
							layout="total, prev, pager, next"
							:total="total"
						>
						</el-pagination>
					</div>
				</el-card>
			</el-main>
			<common-footer></common-footer>
		</div>
	</el-container>
</template>
<script>
import { pageUpdateLog } from '@/config/api'
export default {
	name: 'DownloadView',
	data() {
		return {
			tableData: [],
			pageNum: 1,
			pageSize: 10,
			total: 0,
		}
	},
	computed: {},
	methods: {
		handleSizeChange(val) {
			console.log(`每页 ${val} 条`)
			this.pageSize = val
			this.getData()
		},
		handleCurrentChange(val) {
			this.pageNum = val
			this.getData()
		},
		getData() {
			const params = {
				pageNum: this.pageNum,
				pageSize: this.pageSize,
			}
			pageUpdateLog(params).then(res => {
				console.log('res', res)
				this.tableData = res.records || []
				this.total = res.total
			})
		},
	},
	mounted() {
		this.getData()
	},
}
</script>
<style lang="scss" scoped>
.download-page {
	.page-wrap {
		width: 100%;
	}
	.main {
		margin-top: 60px;
	}
}
</style>
