import axios from 'axios'
// import QS from 'qs'
import { Message } from 'element-ui'
import store from '../store'
import router from '@/router'
import bus from '@/config/bus'

// let baseURL = ''
// if (process.env.NODE_ENV === 'production') {
// 	console.log('NODE_ENV === production')
// 	baseURL = 'http://175.27.250.166'
// } else {
// 	baseURL = 'http://175.27.250.166/'
// 	console.log('NODE_ENV === development')
// }
const http = axios.create({
	baseURL: '',
	timeout: 10 * 1000,
})

const request = async (url, method, params, options) => {
	console.log('url', url, 'params', params)
	return new Promise((resolve, reject) => {
		const requestOpt = {
			url: url,
			method: method,
			headers: options.headers || {},
			...options,
		}
		if (method === 'GET') {
			requestOpt.params = params
		} else if (method === 'POST') {
			if (options.contentType === 'form') {
				requestOpt.headers = { 'content-type': 'application/x-www-form-urlencoded' }
				// requestOpt.data = QS.stringify(params)
				const searchParams = new URLSearchParams()
				Object.keys(params).forEach(key => {
					searchParams.append(key, params[key])
				})
				requestOpt.data = searchParams
			} else {
				requestOpt.data = params
			}
		}
		// 请求拦截器
		const beforeRequest = config => {
			// 设置 token
			const token = localStorage.getItem('token')
			token && (config.headers.Authorization = token)
			return config
		}
		http.interceptors.request.use(beforeRequest)
		try {
			http.request(requestOpt).then(
				resp => {
					if (resp && resp.status === 200) {
						const responseData = resp.data || {}
						if (resp.request.responseType === 'blob') {
							resolve(resp)
							return
						}
						// 手动处理响应数据
						if (options.manually) {
							resolve(resp)
							return
						}
						const { code, data, message } = responseData
						if (!code && code !== 0) {
							resolve(responseData)
							return
						} else if (code === '400') {
							// 登录失效
							// store.dispatch('Logout')
							localStorage.clear()
							sessionStorage.clear()
							store.commit('setIsLogin', false)
							const history = router.history.current.fullPath
							router.push({ path: '/login', query: { redirect: history } })
							bus.$loading().close()
							reject(null)
						} else if (code === '200') {
							resolve(data)
						} else {
							Message.error(message || '数据异常')
							reject(responseData)
						}
					} else {
						Message.error('系统异常')
						reject(resp)
					}
				},
				resp => {
					Message.error('系统异常')
					// console.error(resp)
					reject(resp)
				}
			)
		} catch (error) {
			console.error(error)
		}
	})
}

const get = (url, params = {}, options = {}) => {
	return request(url, 'GET', params, options)
}

const post = (url, data = {}, options = {}) => {
	return request(url, 'POST', data, options)
}

export { get, post }
