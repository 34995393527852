<template>
	<div class="edit-user-modal">
		<el-dialog :title="title" :visible.sync="dialogVisible" width="50%" :close-on-click-modal="false">
			<el-form :model="ruleForm" ref="ruleForm" label-width="100px" class="rule-form">
				<el-form-item
					prop="billType"
					label="抬头类型"
					:rules="[{ required: true, message: '请选择', trigger: 'change' }]"
				>
					<el-radio-group v-model="ruleForm.billType">
						<el-radio :label="'E'">企业</el-radio>
						<el-radio :label="'P'">个人</el-radio>
					</el-radio-group>
				</el-form-item>
				<div class="tip" v-show="ruleForm.billType === 'E'">
					企业发票必须填写纳税人识别号才可作为合法税务（报销）凭证
				</div>
				<template v-if="ruleForm.billType === 'E'">
					<el-form-item
						prop="company"
						label="抬头名称"
						:rules="[{ required: true, message: '请输入', trigger: 'blur' }]"
					>
						<el-input v-model="ruleForm.company" placeholder="请输入企业抬头名称"></el-input>
					</el-form-item>
					<el-form-item prop="taxNo" label="税号" :rules="[{ required: true, message: '请输入', trigger: 'blur' }]">
						<el-input v-model="ruleForm.taxNo" placeholder="请输入纳税人识别号"></el-input>
					</el-form-item>
					<div class="tip">专用发票必须填写以下信息才为有效凭证</div>
					<el-form-item
						prop="companyAddress"
						label="单位地址"
						:rules="[{ required: true, message: '请输入', trigger: 'blur' }]"
					>
						<el-input v-model="ruleForm.companyAddress" placeholder="请输入单位地址"></el-input>
					</el-form-item>
					<el-form-item
						prop="companyTel"
						label="电话号码"
						:rules="[{ required: true, validator: validatePhone, trigger: 'blur' }]"
					>
						<el-input v-model="ruleForm.companyTel" placeholder="请输入电话号码" :maxLength="11"></el-input>
					</el-form-item>
					<el-form-item
						prop="bankName"
						label="开户银行"
						:rules="[{ required: true, message: '请输入', trigger: 'blur' }]"
					>
						<el-input v-model="ruleForm.bankName" placeholder="请输入开户银行"></el-input>
					</el-form-item>
					<el-form-item
						prop="bankNum"
						label="银行账号"
						:rules="[{ required: true, message: '请输入', trigger: 'blur' }]"
					>
						<el-input v-model="ruleForm.bankNum" placeholder="请输入银行账号"></el-input>
					</el-form-item>
					<div class="tip">如需接收电子发票请填写您的邮箱</div>
					<el-form-item
						prop="eamil"
						label="邮箱"
						:rules="[
							{ required: true, message: '请输入邮箱', trigger: 'blur' },
							{ type: 'email', message: '邮箱格式不正确', trigger: 'blur' },
						]"
					>
						<el-input v-model="ruleForm.eamil" placeholder="请输入邮箱"></el-input>
					</el-form-item>
				</template>
				<template v-if="ruleForm.billType === 'P'">
					<el-form-item
						prop="company"
						label="抬头名称"
						:rules="[{ required: true, message: '请输入', trigger: 'blur' }]"
					>
						<el-input v-model="ruleForm.company" placeholder="请输入姓名"></el-input>
					</el-form-item>
					<!-- <el-form-item prop="taxNo" label="税号">
						<el-input v-model="ruleForm.taxNo" placeholder="请输入纳税人识别号"></el-input>
					</el-form-item> -->
					<div class="tip">如需接收电子发票请填写您的邮箱</div>
					<el-form-item
						prop="eamil"
						label="邮箱"
						:rules="[
							{ required: true, message: '请输入邮箱', trigger: 'blur' },
							{ type: 'email', message: '邮箱格式不正确', trigger: 'blur' },
						]"
					>
						<el-input type="email" v-model="ruleForm.eamil" placeholder="请输入邮箱"></el-input>
					</el-form-item>
				</template>
			</el-form>
			<span slot="footer" class="dialog-footer">
				<el-button @click="handleCancel">取 消</el-button>
				<el-button type="primary" @click="submitForm">确 定</el-button>
			</span>
		</el-dialog>
	</div>
</template>
<script>
import { addInvoiceHeader, editInvoiceHeader } from '@/config/api.js'
export default {
	name: 'EditAddress',
	components: {},
	props: {
		title: {
			type: String,
			default: '',
		},
		propsData: {
			type: Object,
			default: () => {
				return {}
			},
		},
	},
	data() {
		return {
			dialogVisible: false,
			ruleForm: {
				billType: 'E',
			},
			formName: 'ruleForm',
		}
	},
	methods: {
		show() {
			this.dialogVisible = true
		},
		handleChange(data) {
			console.log('data', data)
		},
		validatePhone(rule, value, callback) {
			if (!value) {
				callback(new Error('请输入电话号码'))
			} else {
				const regular = /^1[3456789]\d{9}$/
				if (!regular.test(value)) {
					callback(new Error('手机号格式不正确'))
					return
				}
			}
			callback()
		},
		changeInvoiceType(val) {
			if (val === 1) {
				this.ruleForm.billType = 'E'
			}
		},
		handleCancel() {
			this.dialogVisible = false
			this.resetForm()
		},
		resetForm() {
			this.$refs[this.formName].resetFields()
		},
		submitForm() {
			this.$refs[this.formName].validate(valid => {
				if (valid) {
					if (this.propsData.item) {
						this.editSubmit()
					} else {
						this.addSubmit()
					}
				} else {
					console.log('error submit!!')
					return false
				}
			})
		},
		addSubmit() {
			const params = {
				...this.ruleForm,
			}
			this.$loading()
			addInvoiceHeader(params).then(
				() => {
					this.$loading().close()
					this.$message.success('添加成功')
					this.handleCancel()
					this.$emit('finished')
				},
				() => {
					this.$loading().close()
				}
			)
		},
		editSubmit() {
			const params = {
				...this.ruleForm,
			}
			delete params.isDefault
			this.$loading()
			editInvoiceHeader(params).then(
				() => {
					this.$loading().close()
					this.$message.success('添加成功')
					this.handleCancel()
					this.$emit('finished')
				},
				() => {
					this.$loading().close()
				}
			)
		},
	},
	watch: {
		dialogVisible() {
			if (this.dialogVisible) {
				if (this.propsData.item) {
					this.ruleForm = this.propsData.item
				} else {
					this.ruleForm = { billType: 'E' }
				}
				console.log(this.ruleForm, 'this.ruleForm')
			}
		},
	},
	mounted() {},
}
</script>
<style lang="scss" scoped>
.tip {
	padding: 10px;
	line-height: 20px;
	margin-bottom: 10px;
	background-color: #eee;
}
</style>
