<template>
	<div class="edit-user-modal">
		<el-dialog title="选择发票抬头" :visible.sync="dialogVisible" width="50%" :close-on-click-modal="false">
			<el-form :model="ruleForm" ref="ruleForm" label-width="120px" class="rule-form">
				<el-form-item
					label="发票类型"
					style="margin-bottom: 0px"
					prop="invoiceType"
					:rules="[{ required: true, message: '请选择', trigger: 'change' }]"
				>
					<el-radio-group v-model="ruleForm.invoiceType" @input="changeInvoiceType">
						<el-radio border :label="0" name="invoiceType">普通发票</el-radio>
						<el-radio border :label="1" name="invoiceType">专用发票</el-radio>
					</el-radio-group>
				</el-form-item>
				<el-form-item
					label="抬头类型"
					style="margin-bottom: 0px"
					prop="invoiceHeaderType"
					:rules="[{ required: true, message: '请选择', trigger: 'change' }]"
				>
					<el-radio-group v-model="ruleForm.invoiceHeaderType" @input="changeInovieHeaderType">
						<el-radio :label="1" name="invoiceHeaderType">企业单位</el-radio>
						<template v-if="ruleForm.invoiceType === 0">
							<el-radio :label="2" name="invoiceHeaderType">个人/非企业</el-radio>
						</template>
					</el-radio-group>
				</el-form-item>
				<el-form-item
					prop="billId"
					label="选择发票抬头"
					:rules="[{ required: true, message: '请选择', trigger: 'change' }]"
				>
					<el-select v-model="ruleForm.billId" filterable placeholder="请选择发票抬头" @change="handleSelectChange">
						<el-option v-for="item in options" :key="item.id" :label="item.company" :value="item.id"> </el-option>
					</el-select>
					<i
						class="el-icon-circle-plus-outline fs-25 m-l-5 vertical-mid"
						title="去添加发票抬头"
						@click="toInoviceHeader"
					></i>
				</el-form-item>
				<el-form-item label="税号" style="margin-bottom: 0px">
					{{ ruleForm.taxNo }}
				</el-form-item>

				<el-form-item label="" style="margin-bottom: 0px">
					{{ ruleForm.context }}
				</el-form-item>
				<el-form-item label="发票金额" style="margin-bottom: 0px" v-if="propsData.pageFrom !== 'Order'">
					￥{{ ruleForm.totalPrice }}
				</el-form-item>
				<div class="tip">如需接收电子发票请填写您的邮箱</div>
				<div class="p-t-10 p-b-10">
					<el-form-item label="邮箱地址" style="margin-bottom: 0px">
						{{ ruleForm.email }}
					</el-form-item>
				</div>
			</el-form>

			<span slot="footer" class="dialog-footer">
				<el-button @click="handleCancel">取 消</el-button>
				<el-button type="primary" @click="submitForm">确 定</el-button>
			</span>
		</el-dialog>
	</div>
</template>
<script>
import { getInvoiceHeaderList, openInvoiceSubmit } from '@/config/api.js'
export default {
	name: 'SelectInvoiceHeader',
	components: {},
	props: {
		propsData: {
			type: Object,
			default: () => {
				return {}
			},
		},
	},
	data() {
		return {
			dialogVisible: false,
			ruleForm: {
				invoiceType: 0,
				invoiceHeaderType: 1,
				billId: undefined,
				taxNo: '',
				context: '',
				email: '',
			},
			formName: 'ruleForm',
			options: [], // 发票抬头列表
			// companyList_O: [],
			// companyList_Z: [],
			companyList: [],
			personalList: [],
			selectedRows: [],
		}
	},
	methods: {
		show() {
			this.dialogVisible = true
		},
		handleChange(data) {
			console.log('data', data)
		},
		changeInovieHeaderType() {
			this.ruleForm.billId = undefined
			if (this.ruleForm.invoiceHeaderType === 1) {
				this.options = this.companyList
			} else {
				this.options = this.personalList
			}
		},
		changeInvoiceType(val) {
			console.log('val', val)
			if (this.ruleForm.invoiceType === 1) {
				// 专票
				this.ruleForm.invoiceHeaderType = 1
				this.ruleForm.billId = undefined
				this.options = this.companyList
			} else {
				this.ruleForm.billId = undefined
				// 普票
				if (this.ruleForm.invoiceHeaderType === 1) {
					// 企业
					this.options = this.companyList
				} else {
					this.options = this.personalList
				}
			}
		},
		toInoviceHeader() {
			this.$store.commit('/layout/invoiceHeader')
			this.$router.push({ path: '/layout/invoiceHeader' })
		},
		handleSelectChange() {
			this.options.forEach(item => {
				if (item.id === this.ruleForm.billId) {
					this.ruleForm.taxNo = item.taxNo
					this.ruleForm.context = item.context
					this.ruleForm.email = item.eamil
					console.log('item', item)
					this.ruleForm.headerName = item.company
				}
			})
			console.log('this.ruleForm', this.ruleForm)
		},
		handleCancel() {
			this.dialogVisible = false
			this.$emit('cancel')
		},
		submitForm() {
			this.$refs[this.formName].validate(valid => {
				if (valid) {
					if (this.propsData.pageFrom === 'Order') {
						this.dialogVisible = false
						this.$emit('finished', this.ruleForm)
					} else {
						const orderids = []
						this.ruleForm.selectedRows.forEach(item => {
							orderids.push(item.id)
						})
						const params = {
							billId: this.ruleForm.billId,
							orderIdList: orderids,
						}
						this.$loading()
						openInvoiceSubmit(params).then(
							() => {
								this.$loading().close()
								this.dialogVisible = false
								this.$message.success('提交成功')
								this.$emit('finished')
							},
							() => {
								this.$loading().close()
							}
						)
					}
				} else {
					console.log('error submit!!')
					return false
				}
			})
		},
		getInvoiceHeaderList() {
			getInvoiceHeaderList().then(
				res => {
					const list = res || []
					this.personalList = []
					// this.companyList_O = []
					// this.companyList_Z = []
					this.companyList = []
					list.forEach(item => {
						if (item.billType === 'P') {
							this.personalList.push(item)
						} else {
							this.companyList.push(item)
							// if (item.billClass === 0) {
							// 	// 普通发票
							// 	this.companyList_O.push(item)
							// 	this.options = this.companyList_O
							// } else {
							// 	this.companyList_Z.push(item)
							// }
						}
					})
					this.options = this.companyList || []
				},
				() => {
					this.$loading().close()
				}
			)
		},
	},
	mounted() {},
	watch: {
		dialogVisible() {
			if (this.dialogVisible) {
				this.ruleForm = this.propsData
				this.$set(this.ruleForm, 'invoiceHeaderType', 1)
				this.$set(this.ruleForm, 'invoiceType', 0)
				this.$set(this.ruleForm, 'billId', undefined)
				this.getInvoiceHeaderList()
			}
		},
	},
}
</script>
<style lang="scss" scoped>
.tip {
	padding: 10px;
	line-height: 20px;
	margin-bottom: 10px;
	background-color: #eee;
}
</style>
