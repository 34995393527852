<template>
	<div class="page-wrapper">
		<div class="success-view">
			<header class="header">
				<div class="header-left" @click="$router.push({ path: '/index' })">
					<img src="../assets/images/icons/textAndlogo.png" class="logo" />
				</div>
			</header>
			<el-result icon="success" title="订单支付成功" class="m-t-40">
				<template slot="extra">
					<el-button type="primary" size="medium" @click="toOrderList">查看订单</el-button>
				</template>
			</el-result>
		</div>
		<copy-right></copy-right>
	</div>
</template>
<script>
export default {
	name: 'PaymentSuc',
	data() {
		return {}
	},
	methods: {
		toOrderList() {
			this.$store.commit('SET_ACTIVE_MENU', '/layout/orderList')
			this.$router.push({ path: '/layout/orderList' })
		},
	},
}
</script>
<style lang="scss" scoped>
.page-wrapper {
	background: linear-gradient(180deg, #acdeff 0%, #dff7ff 52%, #ffffff 100%);
	width: 100%;
	height: 100%;
}
.success-view {
	.header {
		height: 60px;
		padding: 0 30px;
		display: flex;
		justify-content: space-between;
		background-color: #fff;
		opacity: 0.9;
		.header-left {
			height: 60px;
			display: flex;
			align-items: center;
			.logo {
				width: 180px;
				height: 22px;
				margin-right: 5px;
			}
		}
	}
}
</style>
<style>
.success-view .el-result__icon svg {
	width: 76px;
	height: 76px;
}
.page-wrapper .copy-right {
	position: absolute;
	bottom: 0;
	left: 0;
	padding-top: 10px;
	padding-bottom: 10px;
}
</style>
