<template>
	<el-container class="download-page">
		<common-header fromPage="download"></common-header>
		<div class="page-wrap">
			<el-main class="main">
				<el-card class="box-card" style="min-height: 500px">
					<div slot="header" class="clearfix">
						<span class="text-bold fs-25">下载中心</span>
						<el-button style="float: right; padding: 3px 0" type="text" @click="toUpdateLog">更新日志</el-button>
					</div>
					<div class="">
						<h2>软件</h2>
						<div class="file-list p-t-10" v-if="fileList.length">
							<div class="file-item" v-for="(item, index) in fileList" :key="index">
								<a href="javascript:;" @click="downloadFile(item)">{{ item.fileName }}</a>
							</div>
						</div>
						<div class="p-t-10" v-else>暂无</div>
					</div>
					<div style="margin-top: 50px">
						<h2>知识库</h2>
						<div class="file-list p-t-10" v-if="docList.length">
							<div class="file-item p-t-10 p-b-10" v-for="(item, index) in docList" :key="index">
								<h3>{{ item.title }}</h3>
								<div class="m-t-5">
									<p v-for="f in item.value" :key="f.id">
										<a href="javascript:;" @click="downloadFile(f)">{{ f.fileName }}</a>
									</p>
								</div>
							</div>
						</div>
						<div class="p-t-10" v-else>暂无</div>
					</div>
				</el-card>
			</el-main>
			<common-footer></common-footer>
		</div>
	</el-container>
</template>
<script>
import { pageSoft, pageDoc } from '@/config/api'
export default {
	name: 'DownloadView',
	data() {
		return {
			fileList: [],
			docList: [],
		}
	},
	computed: {
		userInfo() {
			return this.$store.state.userInfo || {}
		},
	},
	methods: {
		toIndex() {
			this.$router.push({ path: '/index' })
		},
		downloadFile(item) {
			// const params = {
			// 	id: item.id,
			// }
			item.url && window.open(item.url)
			// download(params).then(res => {
			// 	console.log('res', res)
			// 	// const fileName = decodeURI(res.headers['content-disposition'].split('=')[1])
			// 	// this.$util.downloadFile(fileName, res.data)
			// })
		},
		toUpdateLog() {
			this.$router.push({ path: '/updateLog' })
		},
		jumpHash(name) {
			if (name === 'index') {
				this.$router.push({ path: '/index' })
			} else if (name === 'product') {
				this.$router.push({ path: '/index', query: { to: 'product' } })
			} else if (name === 'aboutUs') {
				this.$router.push({ path: '/index', query: { to: 'aboutus' } })
			} else if (name === 'download') {
				this.$router.push({ path: '/downloadFile' })
			}
		},
		toDownload() {
			this.$router.push({ path: '/downloadFile' })
		},
		getData() {
			const params = {
				pageNum: 1,
				pageSize: 10,
			}
			pageSoft(params).then(res => {
				this.fileList = res.records || []
			})
			pageDoc(params).then(res => {
				const data = res || {}
				const list = []
				console.log('data', data)
				Object.keys(data).forEach(key => {
					const obj = {
						title: key,
						value: data[key],
					}
					console.log(key, data[key])
					list.push(obj)
				})
				this.docList = list || []
			})
		},
	},
	mounted() {
		this.$store.commit('GET_USER_INFO')
		this.getData()
	},
}
</script>
<style lang="scss" scoped>
.download-page {
	.page-wrap {
		width: 100%;
	}
	.main {
		margin-top: 60px;
	}
	// .header {
	// 	height: 60px !important;
	// 	display: flex;
	// 	.logo-box {
	// 		width: 35%;
	// 		display: flex;
	// 		align-items: center;
	// 	}
	// 	.logo {
	// 		width: 55px;
	// 		height: 34px;
	// 	}
	// 	.logo-text {
	// 		font-size: 20px;
	// 		color: rgb(51, 51, 51);
	// 		font-weight: bold;
	// 		font-family: 'Microsoft YaHei', 微软雅黑;
	// 		margin-left: 5px;
	// 	}
	// }
}
</style>
