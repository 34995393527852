<template>
	<div class="order-list-view">
		<div class="breadcrumb"><i class="icon"></i><span>我的订单</span></div>
		<el-tabs class="tabs" v-model="activeTab" @tab-click="handleClick">
			<el-tab-pane label="待发货" name="wait"></el-tab-pane>
			<el-tab-pane label="已发货" name="delivered"></el-tab-pane>
			<el-tab-pane label="已完成" name="finished"></el-tab-pane>
		</el-tabs>
		<el-card class="box-card order-table">
			<el-table :data="tableData" stripe style="width: 100%">
				<el-table-column prop="date" label="订单详情" width="280">
					<template slot-scope="scope">
						<p class="fs-12 color333">时间: {{ scope.row.createTime }}</p>
						<p class="fs-12 color333 m-t-5">订单号: {{ scope.row.outTradeNo }}</p>
						<p class="fs-12 color333 m-t-5">
							<img class="icon" :src="scope.row.productIcon" />
							<span class="font-bold fs-12 p-r-10">{{ scope.row.productName }}</span
							><span class="font-bold fs-12">{{ scope.row.comboName }}</span>
						</p>
					</template>
				</el-table-column>
				<el-table-column prop="money" label="金额">
					<template slot-scope="scope">
						<el-tag :type="'primary'" disable-transitions>￥{{ scope.row.showPrice }}</el-tag>
					</template>
				</el-table-column>
				<el-table-column prop="orderMemberString" label="对象"> </el-table-column>
				<el-table-column prop="name" label="收货人">
					<template slot-scope="scope">
						<el-popover placement="top-start" :title="scope.row.name" width="200" trigger="hover">
							<p>{{ scope.row.province + scope.row.city + scope.row.area + scope.row.receiveAddress }}</p>
							<p>{{ scope.row.receiveConact }}</p>
							<span slot="reference" class="text-blue">{{ scope.row.receiveName }}</span>
						</el-popover>
					</template>
				</el-table-column>
				<el-table-column prop="receiveStatus" label="状态">
					<template slot-scope="scope">
						<p v-if="scope.row.receiveStatus === '已发货'">
							<el-button type="primary" size="small" plain class="btn" @click="confirmReceive(scope.row)"
								>确认收货</el-button
							>
						</p>
						<p class="status m-t-5 fs-12">{{ scope.row.receiveStatus }}</p>
						<p class="fs-12" v-if="scope.row.deliveryNo">
							{{ scope.row.deliveryNo }}
							<span class="copy-icon" @click="copy(scope.row.deliveryNo)" title="复制"></span>
							<!-- <el-button size="small" plain class="m-l-5" @click="copy(scope.row.deliveryNo)">复制</el-button> -->
						</p>
					</template>
				</el-table-column>
			</el-table>
			<div class="pagination text-right p-t-10" v-if="total > 0">
				<el-pagination
					@current-change="handleCurrentChange"
					:current-page.sync="currentPage"
					:page-size="100"
					layout="total, prev, pager, next"
					:total="total"
				>
				</el-pagination>
			</div>
		</el-card>
	</div>
</template>
<script>
import { listUserWaitingOrder, listUserDeliveryOrder, listUserFinishOrder, confirmDilivery } from '@/config/api'
export default {
	name: 'OrderListView',
	data() {
		return {
			activeTab: 'wait',
			currentPage: 1,
			pageSize: 10,
			total: 0,
			dateTag: 0,
			tableData: [],
		}
	},
	methods: {
		copy(message) {
			this.$copyText(message).then(
				e => {
					this.$message.success('复制成功')
					console.log(e)
				},
				function (e) {
					console.log('复制失败')
					console.log(e)
				}
			)
		},
		handleClick(tab, event) {
			console.log(tab, event)
			if (this.activeTab === 'wait') {
				this.getData()
			} else if (this.activeTab === 'delivered') {
				this.currentPage = 1
				this.getDeliverData()
			} else if (this.activeTab === 'finished') {
				this.currentPage = 1
				this.getFinishedData()
			}
		},
		handleCurrentChange(val) {
			console.log(`当前页: ${val}`)
			this.currentPage = val
			if (this.activeTab === 'delivered') {
				this.getDeliverData()
			} else if (this.activeTab === 'finished') {
				this.getFinishedData()
			}
		},
		confirmReceive(item) {
			this.$loading()
			confirmDilivery({ orderId: item.id }).then(res => {
				this.$loading().close()
				if (res) {
					this.$message.success('收货成功')
					if (this.activeTab === 'delivered') {
						this.getDeliverData()
					} else if (this.activeTab === 'finished') {
						this.getFinishedData()
					}
				}
			})
		},
		getDeliverData() {
			const params = {
				dateTag: this.dateTag,
				pageNum: this.currentPage,
				pageSize: 10,
			}
			listUserDeliveryOrder(params).then(res => {
				this.tableData = res.records || []
				this.total = res.total
			})
		},
		getFinishedData() {
			const params = {
				dateTag: this.dateTag,
				pageNum: this.currentPage,
				pageSize: 10,
			}
			listUserFinishOrder(params).then(res => {
				this.tableData = res.records || []
				this.total = res.total
			})
		},
		getData() {
			const params = {
				dateTag: this.dateTag, // 1近三个月订单 2 今年内订单 3去年订单 4 前年订单
				pageNum: this.currentPage,
				pageSize: this.pageSize,
			}
			listUserWaitingOrder(params).then(res => {
				console.log('listUserWaitingOrder:res', res)
				this.tableData = res.records || []
				this.total = res.total
			})
		},
	},
	mounted() {
		this.getData()
	},
}
</script>
<style lang="scss" scoped>
.order-list-view {
	.breadcrumb {
		font-size: 12px;
		.icon {
			display: inline-block;
			width: 15px;
			height: 12px;
			background: url(../assets/images/icons/path.png) no-repeat;
			background-size: 100%;
			margin-right: 6px;
		}
	}
	.tabs {
		padding-top: 20px;
	}
	.order-table {
		.icon {
			width: 20px;
			height: 20px;
			display: inline-block;
			background: url(../assets/images/icons/order-icon.png) no-repeat;
			background-size: 100%;
			vertical-align: middle;
			margin-right: 10px;
			margin-top: -2px;
		}
	}
	.btn {
		padding: 5px 8px;
	}
	.copy-icon {
		width: 12px;
		height: 16px;
		display: inline-block;
		background: url(../assets/images/icons/copy-icon.png) no-repeat;
		background-size: 100%;
		vertical-align: middle;
		margin-top: -2px;
		cursor: pointer;
	}
}
</style>
<style>
.el-aside {
	background-color: #fff !important;
	/* width: 180px !important; */
	text-align: center;
}
.el-tabs__nav-wrap::after {
	background-color: transparent;
}
.el-pagination {
	font-size: 12px;
}
.el-pagination .btn-next,
.btn-prev {
	min-width: 28px !important;
	border: 1px solid #eee !important;
	padding: 0 !important;
	margin-right: 5px !important;
}
.el-pager .number {
	border: 1px solid #eee !important;
	min-width: 28px !important;
	margin-right: 5px;
}
</style>
