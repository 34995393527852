import Vue from 'vue'
import VueRouter from 'vue-router'
import routerConfig from './router.config'

Vue.use(VueRouter)

const routes = routerConfig

let originPush = VueRouter.prototype.push //备份原push方法
VueRouter.prototype.push = function (location, resolve, reject) {
	if (resolve && reject) {
		//如果传了回调函数，直接使用
		originPush.call(this, location, resolve, reject)
	} else {
		//如果没有传回调函数，手动添加
		originPush.call(
			this,
			location,
			() => {},
			() => {}
		)
	}
}

const router = new VueRouter({
	routes,
})

// 路由拦截，判断是否需要登录
router.beforeEach((to, from, next) => {
	if (to.meta.title) {
		// 判断是否有标题
		// document.title = to.meta.title
		document.title = '翌兽-天宇飞扬'
	}
	// 通过requiresAuth判断当前路由导航是否需要登录
	if (to.matched.some(record => record.meta.requiresAuth)) {
		let token = localStorage.getItem('token')
		// 若需要登录访问，检查是否为登录状态
		if (!token) {
			next({
				path: '/login',
				query: { redirect: to.fullPath },
			})
		} else {
			next()
		}
	} else {
		next() // 确保一定要调用 next()
	}
})

export default router
