<template>
	<div class="user-info-view">
		<!-- <div class="breadcrumb"><i class="icon"></i><span>用户资料</span></div> -->
		<div class="user-info">
			<div class="left">
				<img class="user-pic" src="../assets/images/order/user-pic.png" />
				<div class="p-l-10">
					<p class="fs-16 font-bold">{{ userInfo.nickName }}</p>
					<p class="fs-14">{{ userInfo.phone }}</p>
				</div>
			</div>
			<div class="change-pswd fs-15">
				<i class="el-icon-unlock fs-18"></i>
				<span class="color-blue underline pointer" @click="toForgotPswd"> 更改密码 </span>
			</div>
		</div>
		<el-card class="box-card subscription-info m-t-20">
			<div slot="header" class="clearfix">
				<span>订阅信息</span>
			</div>
			<div class="content">
				<template v-for="(item, index) in subscribeInfo">
					<div :class="{ 'p-l-20': index > 0 }" :key="index">
						<img :src="item.iconBase64" v-if="item.iconBase64" /><span class="fs-18"
							>剩余{{ item.totalDays }}{{ item.feeTypeStr }}</span
						>
					</div>
				</template>
			</div>
		</el-card>
		<!-- <el-card class="box-card">
			<div slot="header" class="clearfix">
				<span>订单历史记录</span>
			</div>
			<div class="history-order">
				<div class="order-list">
					<el-table :data="tableData">
						<el-table-column prop="date" label="订单详情" width="280">
							<template slot-scope="scope">
								<p class="fs-12 color333">时间: {{ scope.row.createTime }}</p>
								<p class="fs-12 color333 m-t-5">订单号: {{ scope.row.outTradeNo }}</p>
								<p class="fs-12 color333 m-t-5">
									<img class="icon" :src="scope.row.productIcon" />
									<span class="font-bold fs-12 p-r-10">{{ scope.row.productName }}</span
									><span class="font-bold fs-12">{{ scope.row.comboName }}</span>
								</p>
							</template>
						</el-table-column>
						<el-table-column prop="money" label="金额">
							<template slot-scope="scope">
								<el-tag :type="'primary'" disable-transitions>￥{{ scope.row.showPrice }}</el-tag>
							</template>
						</el-table-column>
						<el-table-column prop="orderMemberString" label="对象"> </el-table-column>
					</el-table>
				</div>
				<div class="to-all-order text-blue pointer" @click="toOrderList" v-if="tableData.length > 0">
					<span>全部订单</span><i class="el-icon-arrow-right"></i>
				</div>
			</div>
		</el-card> -->
	</div>
</template>
<script>
import { loadUserProductSubscribe, listUserAllOrder } from '@/config/api'
export default {
	name: 'UserInfo',
	data() {
		return {
			subscribeInfo: [],

			tableData: [
				// {
				// 	date: '2016-05-02',
				// 	time: '2023年6月1日 18: 00: 0',
				// 	order: '123456777',
				// 	productName: 'AI调色',
				// 	month: '一个月',
				// 	money: '￥1999',
				// 	obj: 'LUXIANLIANG',
				// 	address: '广东省深圳市宝安区XXXXXXXXX',
				// 	phone: '18839994444',
				// 	name: 'P1',
				// 	status: '已发货',
				// 	orderNo: 'SF123456789',
				// },
			],
		}
	},
	computed: {
		userInfo() {
			return this.$store.state.userInfo
		},
	},
	methods: {
		toForgotPswd() {
			this.$router.push({ name: 'ForgotPswd', params: { title: '更改密码' } })
		},
		toOrderList() {
			this.$store.commit('SET_ACTIVE_MENU', '/layout/orderList')
			this.$router.push({ path: '/layout/orderList' })
		},
		getUserInfo() {
			this.$store.commit('GET_USER_INFO')
		},
		getSubscribeInfo() {
			loadUserProductSubscribe().then(res => {
				this.subscribeInfo = res || []
			})
		},
		getWaitOrderData() {
			const params = {
				dateTag: 0, // 1近三个月订单 2 今年内订单 3去年订单 4 前年订单
				pageNum: 1,
				pageSize: 2,
			}
			listUserAllOrder(params).then(res => {
				this.tableData = res.records || []
				this.total = res.total
			})
		},
	},
	mounted() {
		this.getUserInfo()
		this.getSubscribeInfo()
		this.getWaitOrderData()
	},
}
</script>
<style lang="scss" scoped>
.user-info-view {
	.breadcrumb {
		font-size: 12px;
		.icon {
			display: inline-block;
			width: 15px;
			height: 12px;
			background: url(../assets/images/icons/path.png) no-repeat;
			background-size: 100%;
			margin-right: 6px;
		}
	}
	.user-info {
		display: flex;
		justify-content: space-between;
		.left {
			display: flex;
			align-items: center;
		}
		.user-pic {
			width: 60px;
			height: 60px;
		}
	}
	.subscription-info {
		.content {
			display: flex;
			align-items: center;
		}
		img {
			width: 28px;
			height: 28px;
			display: inline-block;
			vertical-align: middle;
			margin-right: 15px;
		}
	}
	.history-order {
		display: flex;
		align-items: center;
		.order-list {
			width: 80%;
		}
		.icon {
			width: 20px;
			height: 20px;
			display: inline-block;
			background: url(../assets/images/icons/order-icon.png) no-repeat;
			background-size: 100%;
			vertical-align: middle;
			margin-right: 10px;
			margin-top: -2px;
		}
		.to-all-order {
			margin-left: 20px;
		}
	}
}
</style>
