<template>
	<div class="invoice-info-modal">
		<el-dialog title="开票详情" :visible.sync="dialogVisible" width="30%" :close-on-click-modal="false">
			<el-card class="box-card" style="">
				<el-form :model="infoForm" ref="infoForm" label-width="120px" class="rule-form">
					<el-form-item label="开票状态" style="margin-bottom: 0px">
						<p>{{ infoForm.status }}</p>
					</el-form-item>
					<el-form-item label="发票抬头" style="margin-bottom: 0px">
						<p>{{ infoForm.title }}</p>
					</el-form-item>
					<el-form-item label="税号" style="margin-bottom: 0px">
						{{ infoForm.taxNo }}
					</el-form-item>
					<el-form-item label="提交时间" style="margin-bottom: 0px">
						{{ infoForm.createTime }}
					</el-form-item>
					<el-form-item label="发票金额" style="margin-bottom: 0px">
						￥<span class="fs-20">{{ infoForm.showBillAmount }}</span>
					</el-form-item>
					<el-form-item label="电子邮箱" style="margin-bottom: 0px">
						{{ infoForm.email }} <span class="m-l-5" v-if="infoForm.status === '已开票'">已发送</span>
					</el-form-item>
				</el-form>
			</el-card>
			<span slot="footer" class="dialog-footer"> </span>
		</el-dialog>
	</div>
</template>
<script>
export default {
	name: 'InvoiceDetail',
	components: {},
	props: {
		propsData: {
			type: Object,
			default: () => {
				return {}
			},
		},
	},
	data() {
		return {
			dialogVisible: false,
			infoForm: {},
			formName: 'infoForm',
		}
	},
	methods: {
		show() {
			this.dialogVisible = true
		},
		handleCancel() {
			this.dialogVisible = false
		},
	},
	mounted() {},
	watch: {
		dialogVisible() {
			if (this.dialogVisible) {
				this.infoForm = this.propsData
				console.log('this.infoForm', this.infoForm)
			}
		},
	},
}
</script>
<style lang="scss" scoped>
.tip {
	padding: 10px;
	line-height: 20px;
	margin-bottom: 10px;
	background-color: #eee;
}
</style>
<style>
.invoice-info-modal .el-dialog__body {
	padding: 10px;
}
</style>
