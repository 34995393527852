<template>
	<div class="page-wrapper">
		<div class="order-view">
			<header class="header">
				<div class="header-left pointer" @click="$router.push({ path: '/index' })">
					<img src="../assets/images/icons/textAndlogo.png" class="logo" />
				</div>
				<div class="header-right">
					<span class="goback"><el-link class="color-blue" @click="goBack">返回</el-link></span>
				</div>
			</header>
			<div class="main" @click="handleGlobalBlur">
				<div class="receiver-section bg-white" v-if="data.showAddress">
					<div class="title">
						<div class="fs-16 font-bold">收货人信息</div>
						<a href="javascript:;" @click="addAddress" class="fs-15 color-blue">新增收货地址</a>
					</div>
					<div class="address-list" v-if="addressList.length">
						<div class="address-item" v-if="showMore">
							<span class="item-1 selected"
								><span class="p-r-5">{{ selectedAddr.name }}</span
								>{{ selectedAddr.province }}</span
							>
							<span class="p-l-10 p-r-10">{{ selectedAddr.name }} </span>
							<span class="p-r-5">{{ selectedAddr.province }}</span>
							<span class="p-r-5">{{ selectedAddr.city }}</span>
							<span class="p-r-5">{{ selectedAddr.area }}</span>
							<span class="p-r-10 address ellipsis">{{ selectedAddr.address }}</span>
							<span>{{ $util.toSecretPhone(selectedAddr.phone) }}</span>
							<a href="javascript:;" @click="eidtAddress(selectedAddr)" class="fs-13 color-blue m-l-10">编辑</a>
						</div>
						<template v-if="!showMore">
							<template v-for="(item, index) in addressList">
								<!-- <template v-if="index < 1">
								<div class="address-item" :key="index" @click="selectAddr(item)">
									<span class="item-1" :class="{ selected: item.id === selectedAddr.id }"
										><span class="p-r-5">{{ item.name }}</span
										>{{ item.province }}</span
									>
									<span class="p-l-10 p-r-10">{{ item.name }} </span>
									<span class="p-r-5">{{ item.province }}</span>
									<span class="p-r-5">{{ item.city }}</span>
									<span class="p-r-5">{{ item.area }}</span>
									<span class="p-r-10 address ellipsis">{{ item.address }}</span>
									<span>{{ $util.toSecretPhone(item.phone) }}</span>
									<a href="javascript:;" @click="eidtAddress(item)" class="fs-13 color-blue m-l-10">编辑</a>
								</div>
							</template> -->
								<div class="address-item" :key="index" @click="selectAddr(item)">
									<span class="item-1" :class="{ selected: item.id === selectedAddr.id }"
										><span class="p-r-5">{{ item.name }}</span
										>{{ item.province }}</span
									>
									<span class="p-l-10 p-r-10">{{ item.name }} </span>
									<span class="p-r-5">{{ item.province }}</span>
									<span class="p-r-5">{{ item.city }}</span>
									<span class="p-r-5">{{ item.area }}</span>
									<span class="p-r-10 address ellipsis">{{ item.address }}</span>
									<span>{{ $util.toSecretPhone(item.phone) }}</span>
									<a href="javascript:;" @click="eidtAddress(item)" class="fs-13 color-blue m-l-10">编辑</a>
								</div>
							</template>
						</template>
					</div>
					<div class="m-t-10 p-l-50">
						<span class="fs-14 color666" v-show="showMore && addressList.length > 1" @click="showMoreAddrList"
							>更多地址<i class="el-icon-d-arrow-right more-icon"></i
						></span>
						<span class="fs-14 color666" v-show="!showMore && addressList.length > 1" @click="showMoreAddrList"
							>收起地址<i class="el-icon-d-arrow-right pick-up-icon"></i
						></span>
					</div>
				</div>
				<div class="fs-16 font-bold p-t-10">商品信息</div>
				<div class="product-Package">
					<div class="packages">
						<template v-for="(item, index) in data.productComboResList">
							<div
								class="package-item"
								:class="{ 'package-selected': item.id == selectedItem.id }"
								:key="index"
								@click="selectPackage(item)"
							>
								<div class="package-icon" v-if="data.productRes.mustBought > 0">
									<p class="fs-10">{{ data.productRes.mustBought }}人</p>
									<p class="fs-10">套餐</p>
								</div>
								<img class="product-icon" :src="data.productRes && data.productRes.icon" />
								<div class="fs-16 font-bold m-t-20">{{ item.name }}</div>
								<div v-if="item.hasDiscount">
									<div class="fs-16 color-brown font-bold m-t-5">
										<span class="fs-12">官方价</span><span class="through-line">￥{{ item.showPrice }}</span>
									</div>
									<div class="fs-20 color-brown font-bold m-t-5">￥{{ item.discountPriceString }}</div>
								</div>
								<div v-else>
									<div class="fs-20 color-brown font-bold m-t-5">￥{{ item.showPrice }}</div>
								</div>
								<!-- <div class="fs-20 color-brown font-bold m-t-10">￥{{ item.showPrice }}</div> -->
								<div class="fs-15 m-t-10" v-if="item.everyMonth">￥{{ item.everyMonth }}/月</div>
							</div>
						</template>
					</div>
					<div class="select-package">
						<div>
							<!-- <span class="package-flag" v-if="data.productRes && data.productRes.mustBought > 0"
								>{{ data.productRes && data.productRes.mustBought }}人套餐</span
							> -->
							<span class="fs-20 font-bold">{{ data.productRes && data.productRes.name }} </span>
							<p class="fs-20 font-bold select-item">
								<span
									>{{ selectedItem.name }} <i class="el-icon-warning fs-12 tip pointer" title="一个月按30天计算"></i
								></span>
							</p>
						</div>
						<div class="m-t-10">
							<span class="label">买给谁：</span>
							<el-select
								v-model="names"
								multiple
								placeholder="请选择"
								class="select-member"
								@change="handleSelectChange"
							>
								<el-option
									v-for="item in toWhomResList"
									:key="item.userName"
									:label="item.nickName"
									:value="item.userName"
								>
								</el-option>
							</el-select>
							<i
								class="el-icon-circle-plus-outline fs-25 m-l-5 vertical-mid"
								title="点击它您可以去创建其他成员"
								@click="toMyTeam"
							></i>
						</div>
						<div class="errMsg" v-if="errorMsg">{{ errorMsg }}</div>
						<div class="extra-desc">
							<span v-if="data.productRes.mustBought === 0 && names.length > 0 && !errorMsg"
								>数量x{{ names.length }}</span
							>
							<!-- <span class="color-red fs-13 p-l-5" v-show="dongleNum > 0 && data.showAddress && !errorMsg"
								>赠送{{ dongleNum }}个加密狗</span
							> -->
						</div>
					</div>
				</div>
				<el-card class="box-card invoice-info">
					<div slot="header">
						<span class="fix-info"
							>发票信息
							<i class="el-icon-question m-l-5 fs-16" @mouseover="isHover = true" @mouseleave="isHover = false"></i>
							<div class="tips-new-white" v-show="isHover">
								<b></b
								><span><i id="fr_000001" class="wahaha-icon"></i>开企业抬头发票须填写纳税人识别号，以免影响报销</span>
							</div>
						</span>
					</div>
					<div>
						<el-radio-group v-model="isOpenInvoivce" size="small" @input="isOpenInvoivceChange">
							<el-radio label="1" border>不开发票</el-radio>
							<el-radio label="2" border>选择开票</el-radio>
						</el-radio-group>
					</div>
					<!-- <div class="m-t-10" v-if="!invoiceHeaderInfo.headerName">
						<span>不开发票</span><span class="modify color-blue" @click="showInvoiceHeader">修改</span>
					</div> -->
					<div v-if="invoiceHeaderInfo.headerName && isOpenInvoivce === '2'" class="m-t-20">
						<span>发票抬头：{{ invoiceHeaderInfo.headerName }} </span
						><span class="m-l-10">税号：{{ invoiceHeaderInfo.taxNo }}</span>
						<span class="modify color-blue pointer" @click="showInvoiceHeader">修改</span>
					</div>
				</el-card>
				<div class="text-right p-r-20 m-t-40">
					<div class="total" v-show="totalPrice > 0">￥{{ totalPrice }}</div>
					<div class="fs-10 text-right m-t-10 m-b-10">
						<span class="color-red">※</span>本产品为特殊商品，一经售出，不支持退货，请酌情购买！
					</div>
					<div class="m-t-20 fs-12">
						<el-checkbox v-model="checked" class="p-r-5"></el-checkbox>我已阅读并同意
						<router-link to="/userContract">《用户协议》</router-link>和<router-link to="/privacyPolicy"
							>《隐私政策》</router-link
						>
					</div>
					<div class="m-t-10">
						<el-button type="primary" size="large" @click="orderPay">下单支付</el-button>
					</div>
				</div>
			</div>
		</div>
		<common-footer pageFrom="order"></common-footer>
		<EditAddress ref="editAddress" :title="title" @finished="handleFinish" :propsData="propsData"></EditAddress>
		<SelectInvoiceHeader
			ref="selectInvoiceHeader"
			:propsData="propsData"
			@finished="handleFinished"
			@cancel="handleCancel"
		></SelectInvoiceHeader>
	</div>
</template>
<script>
import { getProductDetailByUser, listAddressByUser, createOrder, calculateAmount } from '@/config/api'
import EditAddress from './common/EditAddress.vue'
import SelectInvoiceHeader from './common/SelectInvoiceHeader.vue'
export default {
	name: 'OrderView',
	components: {
		EditAddress,
		SelectInvoiceHeader,
	},
	data() {
		return {
			title: '',
			propsData: {},
			data: {
				productRes: {},
			},
			isOpenInvoivce: '1',
			isHover: false,
			receiverName: '',
			provice: '',
			city: '',
			area: '',
			street: '',
			address: '',
			phone: '',
			toWhomResList: [],
			showMore: true,
			names: [],
			checked: undefined,
			selectedItem: {}, // 选择的套餐
			dongleNum: 0, //赠送加密狗数量
			totalPrice: 0,
			addressList: [],
			selectedAddr: {}, // 选择的收货地址
			invoiceHeaderInfo: {},
			errorMsg: '',
		}
	},
	computed: {
		userInfo() {
			console.log('userInfo', this.$store.state.userInfo)
			return this.$store.state.userInfo
		},
	},
	methods: {
		jumpHash(name) {
			if (name === 'index') {
				this.$router.push({ path: '/index' })
			} else if (name === 'product') {
				this.$router.push({ path: '/index', query: { to: 'product' } })
			} else if (name === 'aboutUs') {
				this.$router.push({ path: '/index', query: { to: 'aboutus' } })
			} else if (name === 'download') {
				this.$router.push({ path: '/downloadFile' })
			}
		},
		toMyTeam() {
			this.$store.commit('SET_ACTIVE_MENU', '/layout/myTeam')
			this.$router.push({ path: '/layout/myTeam' })
		},
		getUserInfo() {
			this.$store.commit('GET_USER_INFO')
		},
		isOpenInvoivceChange(val) {
			if (val === '2' && !this.invoiceHeaderInfo.billId) {
				this.showInvoiceHeader()
			}
		},
		addAddress() {
			this.title = '新增收货地址'
			this.$refs.editAddress.show()
		},
		eidtAddress(item) {
			this.title = '编辑收货地址'
			this.propsData = {
				item: { ...item },
			}
			this.$refs.editAddress.show()
		},
		showInvoiceHeader() {
			this.propsData = {
				pageFrom: 'Order',
			}
			this.$refs.selectInvoiceHeader.show()
		},
		handleFinish() {
			this.getUserAddressList()
		},
		handleFinished(info) {
			console.log('info', info)
			this.invoiceHeaderInfo = info
		},
		handleCancel() {
			if (!this.invoiceHeaderInfo.billId) {
				this.isOpenInvoivce = '1'
			}
		},
		showMoreAddrList() {
			this.showMore = !this.showMore
		},
		selectAddr(item) {
			this.selectedAddr = item
			this.showMoreAddrList()
		},
		goBack() {
			// this.$router.push({ path: '/buyNow' })
			this.$router.back()
		},
		handleGlobalBlur() {
			if (
				this.data.productRes.mustBought > 0 &&
				this.names.length &&
				this.names.length !== this.data.productRes.mustBought
			) {
				this.errorMsg = '购买人数与套餐人数不一致'
			} else {
				this.errorMsg = ''
			}
		},
		orderPay() {
			if (!this.names.length) {
				this.$message.warning('请选择给谁买')
				return
			}
			if (!this.checked) {
				this.$message.warning('请选择协议')
				return
			}
			const params = {
				// billTitleId: this.invoiceHeaderInfo.billId,
				num: this.names.length,
				orderMember: this.names,
				price: this.totalPrice * 100,
				productComboId: this.selectedItem.id,
				productId: this.$route.query.productId,
				billClass: this.invoiceHeaderInfo.invoiceType,
				// userAddressId: this.selectedAddr.id,
			}
			console.log('this.isOpenInvoivce', this.isOpenInvoivce)
			if (this.isOpenInvoivce === '2' && this.invoiceHeaderInfo.billId) {
				params.billTitleId = this.invoiceHeaderInfo.billId
			}
			if (this.data.showAddress) {
				params.userAddressId = this.selectedAddr.id
			}
			this.$loading()
			createOrder(params).then(
				res => {
					this.$loading().close()
					localStorage.setItem('orderCreateResponses', JSON.stringify(res.orderCreateResponses))
					localStorage.setItem('outTradeNo', res.outTradeNo)
					const productInfo = {
						productName: this.data.productRes.name,
						packageName: this.selectedItem.name,
						price: this.totalPrice,
						orderMember: this.names,
					}
					localStorage.setItem('productInfo', JSON.stringify(productInfo))
					// this.$message.success('下单成功')
					this.$router.push({ path: '/payment' })
				},
				() => {
					this.$loading().close()
				}
			)
		},
		selectPackage(item) {
			this.selectedItem = item
		},
		handleSelectChange() {
			console.log('this.name' + this.names)
			const dongles = []
			// this.totalPrice = this.names.length * this.selectedItem.showPrice
			this.names.forEach(name => {
				this.toWhomResList.forEach(who => {
					if (name === who.userName) {
						if (!who.hasBuy) {
							dongles.push(who)
						}
					}
				})
			})
			this.dongleNum = dongles.length
			const params = {
				num: this.names.length,
				productComboId: this.selectedItem.id,
				productId: this.$route.query.productId,
			}
			calculateAmount(params).then(res => {
				this.totalPrice = res.showPrice
			})
		},
		getOrderInfo() {
			this.$loading()
			getProductDetailByUser({ productId: this.$route.query.productId }).then(
				res => {
					this.$loading().close()
					this.data = res
					this.data.productRes = res.productRes || {}
					this.toWhomResList = res.toWhomResList || []
					this.selectedItem = res.productComboResList && res.productComboResList.length && res.productComboResList[0]
				},
				() => this.$loading().close()
			)
		},
		getUserAddressList() {
			listAddressByUser().then(res => {
				this.addressList = res || []
				let hasDefault = false
				this.addressList.forEach(item => {
					if (item.isDefault) {
						this.selectedAddr = item
						hasDefault = true
					}
				})
				if (!hasDefault) {
					this.selectedAddr = this.addressList.length && this.addressList[0]
				}
			})
		},
	},
	mounted() {
		this.getUserInfo()
		this.getOrderInfo()
		this.getUserAddressList()
	},
}
</script>
<style lang="scss" scoped>
.page-wrapper {
	background: linear-gradient(180deg, #acdeff 0%, #dff7ff 52%, #ffffff 100%);
	width: 100%;
	height: 100%;
}

.order-view {
	width: 1220px;
	margin: 0 auto;

	.header {
		height: 60px;
		padding: 0 30px;
		display: flex;
		justify-content: space-between;

		.header-left {
			height: 60px;
			display: flex;
			align-items: center;

			.logo {
				width: 180px;
				height: 22px;
				margin-right: 5px;
			}
		}

		.header-right {
			height: 60px;
			display: flex;
			align-items: center;
			width: 60px;
			text-align: center;
			justify-content: center;
		}
	}

	.main {
		padding: 30px;

		.receiver-section {
			padding: 20px;
			background-color: #fff;
			margin-bottom: 20px;

			.title {
				display: flex;
				justify-content: space-between;
			}

			.address-list {
				padding-left: 50px;
				line-height: 20px;
				color: #666;

				.address-item {
					padding-top: 10px;
				}

				.item-1 {
					display: inline-block;
					// width: 100px;
					height: 20px;
					text-align: center;
					position: relative;
					padding: 4px 10px;
				}

				.selected {
					border: 2px solid #e4393c;
					padding: 4px 10px;

					&::after {
						position: absolute;
						content: '';
						right: 0;
						bottom: 0;
						width: 12px;
						height: 12px;
						background: url(../assets/images/icons/selected-icon.png) no-repeat;
						background-size: 100%;
					}
				}

				.address {
					display: inline-block;
					width: 210px;
					vertical-align: middle;
				}
			}

			.more-icon {
				transform: rotate(90deg);
				font-size: 10px;
				padding-left: 2px;
			}

			.pick-up-icon {
				transform: rotate(270deg);
				font-size: 10px;
				padding-left: 2px;
			}
		}

		.product-Package {
			display: flex;
			padding-top: 30px;

			// justify-content: center;
			.packages {
				display: flex;
			}

			.package-item {
				position: relative;
				width: 160px;
				display: flex;
				flex-direction: column;
				align-items: center;
				padding-top: 20px;
				background-color: #fff;
				border-radius: 10px;
				margin-right: 20px;
				border: 1px solid #ddd;
				padding-bottom: 10px;

				.package-icon {
					position: absolute;
					top: -5px;
					right: -8px;
					width: 50px;
					height: 60px;
					background: url(../assets/images/icons/package-icon.png) no-repeat;
					background-size: 100%;
					display: flex;
					flex-direction: column;
					justify-content: center;
					align-items: center;
					padding-left: 10px;
					color: #db5f17;
					font-weight: bold;
					line-height: 1em;
				}

				.package-icon-5 {
					position: absolute;
					top: -5px;
					right: -8px;
					width: 66px;
					height: 77px;
					background: url(../assets/images/buynow/package-5-icon.png) no-repeat;
					background-size: 100%;
				}

				.product-icon {
					width: 50px;
					height: 50px;
				}
			}

			.package-selected {
				border: 2px solid #e4393c;
			}

			.select-package {
				padding-left: 20px;

				.package-flag {
					display: inline-block;
					width: 70px;
					height: 25px;
					border-radius: 10px;
					text-align: center;
					line-height: 25px;
					background-color: rgb(184, 116, 26);
					color: #fff;
					margin-right: 8px;
				}

				.select-item {
					margin-top: 5px;

					.tip {
						vertical-align: top;
					}
				}

				.select-member {
					width: 300px;
				}

				.extra-desc {
					padding-left: 60px;
					padding-top: 10px;
				}
			}
		}

		.invoice-info {
			margin-top: 50px;

			.tips-new-white {
				position: absolute;
				top: -14px;
				left: 95px;

				span {
					background-color: #fff;
					color: #333;
					padding: 5px 10px;
					vertical-align: middle;
					line-height: 20px;
					margin-top: 5px;
					box-shadow: 0 0 3px rgba(0, 0, 0, 0.15);
					border: 1px solid #ddd;
					width: 342px;
					display: inline-block;
				}
			}

			b {
				position: absolute;
				top: 12px;
				left: -8px;
				width: 9px;
				height: 17px;
				vertical-align: middle;
				background: url(../assets/images/icons/arrow-left.png) no-repeat;
			}

			.fix-info {
				position: relative;
			}

			.wahaha-icon {
				display: inline-block;
				width: 12px;
				height: 16px;
				background: url(../assets/images/icons/light-icon.png) no-repeat;
				vertical-align: middle;
				margin-top: -2px;
				margin-right: 4px;
				color: #333;
			}
		}

		.errMsg {
			width: 280px;
			height: 25px;
			line-height: 25px;
			background-color: #fef2f2;
			border-color: #ffb4a8;
			border: 1px solid #ff934c;
			color: #6c6c6c;
			font-size: 12px;
			margin-top: 5px;
			margin-left: 64px;
			padding-left: 10px;
			color: red;
		}
		.total {
			font-size: 35px;
			color: rgb(184, 116, 26);
			font-weight: bold;
			text-align: right;
		}

		.modify {
			padding-left: 10px;
		}

		a {
			text-decoration: underline;
		}
	}
}
</style>
<style>
.el-input--suffix .el-input__inner {
	/* height: 30px; */
	/* line-height: 30px; */
}

.el-input__icon {
	/* line-height: 30px; */
}
</style>
