<template>
	<div class="invoice-view">
		<div class="breadcrumb"><i class="icon"></i><span>我的发票</span></div>
		<el-tabs class="tabs" v-model="activeName" @tab-click="handleClick">
			<el-tab-pane label="待开票" name="waiting"></el-tab-pane>
			<el-tab-pane label="已失效" name="expire"></el-tab-pane>
			<el-tab-pane label="处理中" name="opening"></el-tab-pane>
			<el-tab-pane label="已开票" name="opened"></el-tab-pane>
		</el-tabs>
		<el-card class="box-card order-table">
			<el-table :data="tableData" style="width: 100%" @selection-change="handleSelectionChange">
				<el-table-column label="全选" type="selection" width="55" v-if="activeName === 'waiting'"> </el-table-column>
				<el-table-column prop="date" label="订单详情" width="280">
					<template slot-scope="scope">
						<p class="fs-12 color333">时间: {{ scope.row.createTime }}</p>
						<p class="fs-12 color333 m-t-5">订单号: {{ scope.row.outTradeNo }}</p>
						<p class="fs-12 color333 m-t-5">
							<img class="icon" :src="scope.row.productIcon" />
							<span class="font-bold fs-12 p-r-10">{{ scope.row.productName }}</span
							><span class="font-bold fs-12">{{ scope.row.comboName }}</span>
						</p>
					</template>
				</el-table-column>
				<el-table-column prop="orderMemberString" label="对象"> </el-table-column>
				<el-table-column prop="receiveName" label="收货人"> </el-table-column>
				<el-table-column prop="money" label="金额">
					<template slot-scope="scope">
						<el-tag :type="'primary'" disable-transitions>￥{{ scope.row.showPrice }}</el-tag>
						<template v-if="scope.row.billStatus === '开票中' || scope.row.billStatus === '已开票'">
							<span class="text-blue underline fs-12 m-l-25" @click="showInvoiceInfo(scope.row.billRes)">开票详情</span>
						</template>
					</template>
				</el-table-column>
				<el-table-column prop="billStatus" label="开票状态">
					<template slot-scope="scope">
						<el-tag type="success">{{ scope.row.billStatus }}</el-tag>
						<p class="p-l-10 p-r-10" v-if="scope.row.billStatus === '已开票'">
							<el-link type="primary" class="fs-12" @click="downloadInvoice(scope.row)">发票下载</el-link>
						</p>
					</template>
				</el-table-column>
			</el-table>
			<div class="m-t-10 text-right" v-if="activeName === 'waiting'">
				<span v-if="selectedRows.length > 0">
					<span class="fs-25 text-bold">￥{{ selectedInfo.total }}</span
					><span class="fs-15 p-l-10">共{{ selectedInfo.orderNum }}个订单</span></span
				>
				<el-button type="primary" size="large" class="m-l-10" @click="toOpenInvoice">去开票</el-button>
			</div>
		</el-card>
		<SelectInvoiceHeader
			ref="selectInvoiceHeader"
			:propsData="propsData"
			@finished="handleFinished"
		></SelectInvoiceHeader>
		<InvoiceDetail ref="invoiceInfo" :propsData="propsData"></InvoiceDetail>
	</div>
</template>
<script>
import { openedInvoice, expireInvoice, openingInvoice, waitingOpenInvoice, downloadWithPermission } from '@/config/api'
import SelectInvoiceHeader from './common/SelectInvoiceHeader.vue'
import InvoiceDetail from './common/InvoiceDetail.vue'
export default {
	name: 'InvoiceView',
	components: { SelectInvoiceHeader, InvoiceDetail },
	data() {
		return {
			activeName: 'waiting',
			propsData: {},
			currentPage: 1,
			pageSize: 10,
			dateFlag: 0, //  0 近1个月 1 近3个月 2 近1年
			tableData: [
				// {
				// 	date: '2016-05-02',
				// 	time: '2023年6月1日 18: 00: 0',
				// 	order: '123456777',
				// 	productName: 'AI调色',
				// 	month: '一个月',
				// 	money: '￥1999',
				// 	obj: 'LUXIANLIANG',
				// 	address: '广东省深圳市宝安区XXXXXXXXX',
				// 	phone: '18839994444',
				// 	name: 'P1',
				// 	status: '已发货',
				// 	orderNo: 'SF123456789',
				// },
			],
			selectedRows: [],
			selectedInfo: {},
		}
	},
	methods: {
		handleClick(tab, event) {
			console.log(tab, event)
			this.currentPage = 1
			if (this.activeName === 'waiting') {
				this.getWaitingData()
			} else if (this.activeName === 'expire') {
				this.getExpireInvoice()
			} else if (this.activeName === 'opening') {
				this.getOpeningInvoice()
			} else if (this.activeName === 'opened') {
				this.getOpenedInvoice()
			}
		},
		showInvoiceInfo(info) {
			this.propsData = {
				...info,
			}
			this.$refs.invoiceInfo.show()
		},
		downloadInvoice(row) {
			const params = {
				id: row.id,
			}
			downloadWithPermission(params).then(res => {
				const fileName = decodeURI(res.headers['content-disposition'].split('=')[1])
				this.$util.downloadFile(fileName, res.data)
			})
		},
		handleFinished() {
			this.getWaitingData()
		},
		handleSelectionChange(val) {
			console.log('va', val)
			this.selectedRows = val
			let total = 0
			this.selectedRows.forEach(item => {
				total += Number(item.showPrice)
			})
			this.selectedInfo = {
				total: total,
				orderNum: this.selectedRows.length,
			}
		},
		toOpenInvoice() {
			if (this.selectedRows.length === 0) {
				this.$message.error('请先勾选发票')
				return
			}
			this.propsData = {
				selectedRows: this.selectedRows,
				totalPrice: this.selectedInfo.total,
				pageFrom: 'invoiceHeader',
			}
			this.$refs.selectInvoiceHeader.show()
		},
		getWaitingData() {
			const params = {
				dateFlag: this.dateFlag, // 1近三个月订单 2 今年内订单 3去年订单 4 前年订单
				pageNum: this.currentPage,
				pageSize: this.pageSize,
			}
			waitingOpenInvoice(params).then(res => {
				this.tableData = res.records || []
				this.total = res.total
			})
		},
		getOpeningInvoice() {
			const params = {
				dateFlag: this.dateFlag, // 1近三个月订单 2 今年内订单 3去年订单 4 前年订单
				pageNum: this.currentPage,
				pageSize: this.pageSize,
			}
			openingInvoice(params).then(res => {
				this.tableData = res.records || []
				this.total = res.total
			})
		},
		getExpireInvoice() {
			const params = {
				dateFlag: this.dateFlag, // 1近三个月订单 2 今年内订单 3去年订单 4 前年订单
				pageNum: this.currentPage,
				pageSize: this.pageSize,
			}
			expireInvoice(params).then(res => {
				this.tableData = res.records || []
				this.total = res.total
			})
		},
		getOpenedInvoice() {
			const params = {
				dateFlag: this.dateFlag, // 1近三个月订单 2 今年内订单 3去年订单 4 前年订单
				pageNum: this.currentPage,
				pageSize: this.pageSize,
			}
			openedInvoice(params).then(res => {
				this.tableData = res.records || []
				this.total = res.total
			})
		},
		handleCurrentChange(val) {
			this.currentPage = val
			if (this.activeName === 'waiting') {
				this.getWaitingData()
			} else if (this.activeName === 'expire') {
				this.getExpireInvoice()
			} else if (this.activeName === 'opening') {
				this.getOpeningInvoice()
			} else if (this.activeName === 'opened') {
				this.getOpenedInvoice()
			}
		},
	},
	mounted() {
		this.getWaitingData()
	},
}
</script>
<style lang="scss" scoped>
.invoice-view {
	.breadcrumb {
		font-size: 12px;
		.icon {
			display: inline-block;
			width: 15px;
			height: 12px;
			background: url(../assets/images/icons/path.png) no-repeat;
			background-size: 100%;
			margin-right: 6px;
		}
	}
	.tabs {
		padding-top: 20px;
	}
	.order-table {
		.icon {
			width: 20px;
			height: 20px;
			display: inline-block;
			background: url(../assets/images/icons/order-icon.png) no-repeat;
			background-size: 100%;
			vertical-align: middle;
			margin-right: 10px;
			margin-top: -2px;
		}
	}
	.btn {
		padding: 5px 8px;
	}
}
</style>
<style>
.el-aside {
	background-color: #fff !important;
	/* width: 180px !important; */
	text-align: center;
}
.el-tabs__nav-wrap::after {
	background-color: transparent;
}
.el-pagination {
	font-size: 12px;
}
.el-pagination .btn-next,
.btn-prev {
	min-width: 28px !important;
	border: 1px solid #eee !important;
	padding: 0 !important;
	margin-right: 5px !important;
}
.el-pager .number {
	border: 1px solid #eee !important;
	min-width: 28px !important;
	margin-right: 5px;
}
</style>
