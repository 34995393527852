<template>
	<div class="my-team-view">
		<div class="btns">
			<el-button type="primay" @click="addUser">添加成员</el-button>
		</div>
		<el-card class="box-card">
			<el-table :data="tableData" stripe style="width: 100%">
				<el-table-column prop="nickName" label="账号" width="280">
					<template slot-scope="scope">
						<span class="user-pic"><img src="../assets/images/order/user-pic.png" /></span>
						<span>{{ scope.row.userInfo && scope.row.userInfo.userName }}</span>
					</template>
				</el-table-column>
				<el-table-column prop="nickName" label="姓名">
					<template slot-scope="scope">
						<span>{{ scope.row.userInfo && scope.row.userInfo.nickName }}</span>
					</template>
				</el-table-column>
				<el-table-column prop="money" label="订阅信息">
					<template slot-scope="scope">
						<span class="prodcut" v-for="(item, index) in scope.row.subscribeRes" :key="index">
							<img :src="item.iconBase64" v-if="item.iconBase64" class="product-icon" />
							<span
								>剩余{{ item.totalDays }}<span>{{ item.feeTypeStr }}</span></span
							>
						</span>
					</template>
				</el-table-column>
				<el-table-column prop="createTime" label="创建日期">
					<template slot-scope="scope">
						<span>{{ scope.row.userInfo && scope.row.userInfo.createTime }}</span>
					</template>
				</el-table-column>
				<el-table-column fixed="right" label="操作" width="100">
					<template slot-scope="scope">
						<span title="修改密码" class="pointer" @click="changePaswd(scope.row.userInfo)"
							><i class="el-icon-unlock fs-20"></i
						></span>
						<span title="删除" @click="deleteTeamUser(scope.row.userInfo)" class="m-l-5 pointer"
							><i class="el-icon-delete fs-20"></i
						></span>
					</template>
				</el-table-column>
			</el-table>
		</el-card>
		<EditUser ref="editUser" @finish="refreshTable"></EditUser>
		<ModifyPassword ref="modifyPassword" @finish="refreshTable" :propsData="propsData"></ModifyPassword>
	</div>
</template>
<script>
import EditUser from './common/EditUser.vue'
import ModifyPassword from './common/ModifyPassword.vue'
import { loadUserTeamSubscribe, deleteTeamUser } from '@/config/api.js'
export default {
	name: 'MyTeamView',
	components: { EditUser, ModifyPassword },
	data() {
		return {
			propsData: {},
			tableData: [],
		}
	},
	methods: {
		addUser() {
			this.$refs.editUser.show()
		},
		deleteTeamUser(item) {
			this.$confirm('确认删除吗?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning',
			})
				.then(() => {
					deleteTeamUser({ userName: item.userName }).then(res => {
						console.log('res', res)
						this.$message.success('删除成功')
						this.getTableData()
					})
				})
				.catch(() => {})
		},
		changePaswd(item) {
			this.propsData = {
				account: item.userName,
				nickName: item.nickName,
			}
			this.$refs.modifyPassword.show()
		},
		refreshTable() {
			this.getTableData()
		},
		getTableData() {
			loadUserTeamSubscribe().then(res => {
				const list = res || []
				this.tableData = list
				console.log('this.tableData', this.tableData)
			})
		},
	},
	mounted() {
		this.getTableData()
	},
}
</script>
<style lang="scss" scoped>
.btns {
	margin-bottom: 10px;
}
.user-pic {
	display: inline-block;
	vertical-align: middle;
	margin-right: 10px;
	width: 55px;
	height: 55px;
}
.prodcut {
	margin-right: 5px;
}
.product-icon {
	display: inline-block;
	vertical-align: middle;
	margin-right: 5px;
	width: 30px;
	height: 30px;
}
</style>
