<template>
	<el-container class="layout-container">
		<el-header class="header">
			<div class="logo-box pointer" @click="toIndex">
				<img class="logo" src="../assets/images/icons/textAndlogo.png" />
			</div>
		</el-header>
		<el-container class="container">
			<el-aside width="200px" style="background-color: rgb(238, 241, 246); overflow: hidden">
				<el-card class="box-card aside-card">
					<el-menu :router="true" @select="handleSelect" :default-active="activePath">
						<el-menu-item index="/layout/userInfo" :class="{ seleted: activePath === '/layout/userInfo' }"
							>用户资料</el-menu-item
						>
						<el-menu-item index="/layout/myTeam" :class="{ seleted: activePath === '/layout/myTeam' }"
							>我的团队</el-menu-item
						>
						<!-- 2023.11.2要求隐藏 -->

						<!-- <el-menu-item index="/layout/orderList" :class="{ seleted: activePath === '/layout/orderList' }"
							>我的订单</el-menu-item
						>
						<el-menu-item index="/layout/address" :class="{ seleted: activePath === '/layout/address' }">
							收货地址</el-menu-item
						>
						<el-menu-item index="/layout/invoice" :class="{ seleted: activePath === '/layout/invoice' }"
							>我的发票</el-menu-item
						>
						<el-menu-item index="/layout/invoiceHeader" :class="{ seleted: activePath === '/layout/invoiceHeader' }"
							>发票抬头</el-menu-item
						> -->
					</el-menu>
				</el-card>
			</el-aside>

			<div class="bg">
				<el-main><router-view /></el-main>
				<copy-right></copy-right>
			</div>
		</el-container>
	</el-container>
</template>
<script>
export default {
	name: 'MenuLayout',
	data() {
		return {}
	},
	computed: {
		activePath() {
			return this.$store.state.activeMenu || sessionStorage.getItem('activeMenu')
		},
	},
	methods: {
		toIndex() {
			this.$router.push({ path: '/index' })
		},
		handleSelect(index) {
			this.$store.commit('SET_ACTIVE_MENU', index)
		},
	},
}
</script>
<style lang="scss" scoped>
.layout-container {
	height: 100%;
	position: relative;
}
.container {
	width: 100%;
	margin: 0 auto;
}

.header {
	display: flex;
	align-items: center;
	width: 100%;
	margin: 0 auto;
	.logo-box {
		display: flex;
		align-items: center;
	}
	.logo {
		width: 170px;
		height: 22px;
	}
	.logo-text {
		font-size: 20px;
		color: rgb(51, 51, 51);
		font-weight: bold;
		font-family: 'Microsoft YaHei', 微软雅黑;
		margin-left: 5px;
	}
}
.bg {
	background: url(../assets/images/order/bg.png) no-repeat;
	min-height: 100%;
	width: calc(100% - 200px);
}
.seleted {
	background-color: #ecf5ff;
}
</style>
<style>
.aside-card {
	height: 100%;
}
.aside-card .el-card__body {
	padding: 0;
}

.layout-container .copy-right {
	position: absolute;
	bottom: 0;
	left: 0;
	padding-top: 10px;
	padding-bottom: 10px;
}
</style>
