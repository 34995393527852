import { post, get } from './http.js'

/**
 * 注册
 * @param {*} params
 * @returns
 */
export const register = (params = {}) => {
	return post('/web/user/register', params)
}

export const captcha = (params = {}) => {
	return post('/web/common/kaptcha', params)
}

export const sendVeriCode = (params = {}) => {
	return post('/web/common/verificationCode', params)
}

export const checkUserExists = (params = {}) => {
	return post('/web/user/checkUserExists', params)
}

/**
 * 登录
 * @param {*} params
 * @returns
 */

export const login = (params = {}) => {
	return post('/web/user/login', params)
}

export const getUserInfo = (params = {}) => {
	return post('/web/user/info/loadUserBasicInfo', params)
}

export const logout = (params = {}) => {
	return post('/web/user/info/loginOut', params)
}

// 绑定邀请人
export const replaceCreateBy = (params = {}) => {
	return post('/web/user/replaceCreateBy', params)
}

/**
 * 我的团队
 * @param {*} params
 * @returns
 */

// export const getAddressData = (params = {}) => {
// 	return post('/web/common/listAllLocation', params)
// }

export const addTeamUser = params => {
	return post('/web/user/team/createUserTeam', params)
}

// 我的团队列表
export const loadUserTeamSubscribe = params => {
	return post('/web/user/team/loadUserTeamSubscribe', params)
}

// 删除团队成员
export const deleteTeamUser = params => {
	return post('/web/user/team/deleteUserTeam', params)
}

// 软件下载
export const pageSoft = params => {
	return post('/web/soft/pageSoft', params)
}

// 分页知识库列表
export const pageDoc = params => {
	return post('/web/soft/pageDoc', params)
}

// 软件下载
export const download = params => {
	return post('/web/common/download', params, { responseType: 'blob' })
}

// 软件下载
export const downloadWithPermission = params => {
	return post('/web/bill/download', params, { responseType: 'blob' })
}

/**
 * 订单列表
 */

// 全部订单
export const listUserAllOrder = params => {
	return post('/web/order/listUserAllOrder', params)
}
// 待发货列表
export const listUserWaitingOrder = params => {
	return post('/web/order/listUserWaitingOrder', params)
}
// 已发货列表
export const listUserDeliveryOrder = params => {
	return post('/web/order/listUserDeliveryOrder', params)
}
// 已完成列表
export const listUserFinishOrder = params => {
	return post('/web/order/listUserFinishOrder', params)
}

// 确认收货
export const confirmDilivery = params => {
	return post('/web/order/confirmDilivery', params)
}

/**
 * 用户资料
 */
export const loadUserProductSubscribe = params => {
	return post('/web/user/info/loadUserProductSubscribe', params)
}
export const changeUserTeamPassword = params => {
	return post('/web/user/team/changeUserTeamPassword', params)
}

/**
 * 立即购买
 */

// 列举所有产品
export const listProduct = params => {
	return post('/web/buy/listProduct', params)
}

// 产品详情页
export const getProductDetailByUser = params => {
	return post('/web/buy/getProductDetailByUser', params)
}
// 购买-下单
export const createOrder = params => {
	return post('/web/buy/createOrder', params)
}

// 查询支付状态
export const queryOrderStatus = params => {
	return post('/web/buy/queryOrderStatus', params)
}

// 购买-计算金额
export const calculateAmount = params => {
	return post('/web/buy/calculateAmount', params)
}
/**
 * 收货地址
 */

// 收货地址列表
export const listAddressByUser = params => {
	return post('/web/user/delivery-address/listAddressByUser', params)
}

export const createAddressByUser = params => {
	return post('/web/user/delivery-address/createAddressByUser', params)
}
export const deleteAddressByUser = params => {
	return post('/web/user/delivery-address/deleteAddressByUser', params)
}
export const updateUserAddressByUser = params => {
	return post('/web/user/delivery-address/updateUserAddressByUser', params)
}

export const setDefaultAddressByUser = params => {
	return post('/web/user/delivery-address/setDefaultAddressByUser', params)
}
export const getCityData = params => {
	return post('/web/common/listAllLocation', params)
}

/**
 * 发票抬头
 */
export const getInvoiceHeaderList = params => {
	return post('/web/user/bill/listBillTitle', params)
}

// 新增发票抬头
export const addInvoiceHeader = params => {
	return post('/web/user/bill/createBillTitle', params)
}

// 删除发票抬头
export const delInvoiceHeader = params => {
	return post('/web/user/bill/deleteBillTitle', params)
}

// 更新发票抬头
export const editInvoiceHeader = params => {
	return post('/web/user/bill/updateBillTitle', params)
}

// 设置默认用户发票抬头
export const setDefaultInvoiceHeader = params => {
	return post('/web/user/bill/setDefaultAddressByUser', params)
}

/***
 * 我的发票
 */

// 待开票
export const openedInvoice = params => {
	return post('/web/bill/pageFinishInvoicedOrder', params)
}

// 待开票
export const expireInvoice = params => {
	return post('/web/bill/pageOutInvoicedOrder', params)
}

// 开票中
export const openingInvoice = params => {
	return post('/web/bill/pagePendingInvoicedOrder', params)
}

// 待开票
export const waitingOpenInvoice = params => {
	return post('/web/bill/pageToBeInvoicedOrder', params)
}

// 提交开票
export const openInvoiceSubmit = params => {
	return post('/web/bill/createBillRecord', params)
}

/**
 * 行为验证码
 */

// 校验
export const checkAndSendCode = params => {
	return post('/web/common/checkAndSendCode', params)
}

export const checkVerificationCode = params => {
	return post('/web/common/checkVerificationCode', params)
}

// 找回密码提交
export const resetUserPassword = params => {
	return post('/web/user/resetUserPassword', params)
}

/**
 * 首页
 */

// 首页-关于我们
export const findAboutUs = () => {
	return get('/web/index/findAboutUs')
}

// 首页-banner
export const findIndexBanner = () => {
	return get('/web/index/findIndexBanner')
}

// 首页-商品信息
export const findIndexProduct = () => {
	return get('/web/index/findIndexProduct')
}

// 下载中心-更新日志
export const pageUpdateLog = params => {
	return post('/web/soft/pageUpdateLog', params)
}
