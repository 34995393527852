<template>
	<el-container class="index-page">
		<el-main class="main-content">
			<common-header fromPage="index"></common-header>
			<div class="banner" id="banner">
				<template v-if="bannerData.length > 0">
					<el-carousel class="carousel-container">
						<el-carousel-item v-for="(item, index) in bannerData" :key="index">
							<a :href="item.linkUrl" target="_blank" style="display: block" v-if="item.linkUrl"
								><img class="banner-img" :src="item.imgUrl + '?index=' + index"
							/></a>
							<a style="display: block" v-else>
								<img class="banner-img" :src="item.imgUrl + '?index=' + index" />
							</a>
							<div class="banner-cont" v-html="item.content"></div>
						</el-carousel-item>
					</el-carousel>
				</template>
			</div>
			<div class="bg">
				<div class="product-list" id="product-list">
					<div class="product-list-title">快人一步，助您易售</div>
					<template v-for="(item, index) in productData">
						<div class="product-item" :class="{ 'first-item': index === 0 }" v-if="index % 2 === 0" :key="index">
							<!-- <img class="product-img-left" src="../assets/images/index/product-img-1.png" /> -->
							<template v-if="item.backageUrl">
								<ZxComparison
									:id="'ZxComparison_' + item.id"
									:width="comparison.width"
									:height="comparison.height"
									:src1="item.imgUrl"
									:src2="item.backageUrl"
									class="product-img-left"
								></ZxComparison>
							</template>
							<template v-else>
								<img class="product-img-left" :src="item.imgUrl" />
							</template>
							<div class="product-info">
								<div>
									<p class="color-blue font-bold fs-30">{{ item.productName }}</p>
									<p class="color-black fs-18 m-t-20" v-html="item.productDescription"></p>
								</div>
							</div>
						</div>
						<div class="product-item" v-else :key="index">
							<div class="product-info">
								<div>
									<p class="color-blue font-bold fs-30">{{ item.productName }}</p>
									<p class="color-black fs-18 m-t-20" v-html="item.productDescription"></p>
								</div>
							</div>
							<template v-if="item.backageUrl">
								<ZxComparison
									:id="'ZxComparison_' + item.id"
									:width="comparison.width"
									:height="comparison.height"
									:src1="item.imgUrl"
									:src2="item.backageUrl"
									class="product-img-2"
								></ZxComparison>
							</template>
							<template v-else>
								<img class="product-img-2" :src="item.imgUrl" />
							</template>
						</div>
					</template>
				</div>
				<div class="about-us" id="aboutUs">
					<div class="wrap">
						<div class="text-center fs-35 font-bold about-us-title">{{ aboutUsData.title }}</div>
						<div class="text-center fs-18 m-t-20 about-us-sub-title" v-html="aboutUsData.desc"></div>
						<div class="list">
							<el-card class="box-card">
								<p><img src="../assets/images/index/us-icon-1.png" class="us-icon-1" /></p>
								<p class="font-bold color-blue fs-18 m-t-10">懂行业</p>
								<p class="font-bold color-blue fs-15 m-t-10 p-b-10">行业沉淀多年，理解行业痛点</p>
							</el-card>
							<el-card class="box-card">
								<p><img src="../assets/images/index/us-icon-2.png" class="us-icon-2" /></p>
								<p class="font-bold color-blue fs-18 m-t-10">懂色彩</p>
								<p class="font-bold color-blue fs-15 m-t-10 p-b-10">强大色彩团队，掌握色彩和图像技术</p>
							</el-card>
							<el-card class="box-card">
								<p><img src="../assets/images/index/us-icon-3.png" class="us-icon-3" /></p>
								<p class="font-bold color-blue fs-18 m-t-10">懂AI</p>
								<p class="font-bold color-blue fs-15 m-t-10 p-b-10">引入先进AI技术，赋能行业</p>
							</el-card>
						</div>
					</div>
				</div>
			</div>
			<common-footer fromPage="index"></common-footer>
		</el-main>
		<el-backtop :bottom="50" :visibility-height="100"></el-backtop>
	</el-container>
</template>

<script>
import ZxComparison from 'zx-comparison'
import { findAboutUs, findIndexBanner, findIndexProduct } from '@/config/api'
export default {
	name: 'IndexView',
	components: { ZxComparison },
	data() {
		return {
			activeIndex: '1',
			isShowMask: false,
			showUserInfo: false,
			comparison: {
				id: 'sliderA',
				width: '100%',
				height: 'auto',
				src1: require('@/assets/images/index/old.jpg'),
				src2: require('@/assets/images/index/new.jpg'),
			},
			bannerData: [],
			productData: [],
			aboutUsData: {},
		}
	},
	computed: {
		isLogin() {
			console.log('this.$store.state.isLogin', this.$store.state.isLogin)
			return this.$store.state.isLogin
		},
		userInfo() {
			return this.$store.state.userInfo || {}
		},
		endLine() {
			const userInfo = this.$store.state.userInfo || {}
			if (userInfo.recentProductNameSubscribe && userInfo.recentProductNameSubscribe.endTime) {
				const arr = userInfo.recentProductNameSubscribe.endTime.split('-')
				if (arr.length === 3) {
					return arr[0] + '年' + arr[1] + '月' + arr[2] + '日'
				}
			}

			return ''
		},
	},
	methods: {
		jumpHash(name) {
			if (name === 'banner') {
				window.location.hash = '#banner'
			} else if (name === 'product') {
				window.location.hash = '#product-list'
			} else if (name === 'aboutUs') {
				window.location.hash = '#aboutUs'
			} else if (name === 'download') {
				this.$router.push({ path: '/downloadFile' })
			}
		},
		toDownload() {
			this.$router.push({ path: '/downloadFile' })
		},
		toUserInfoPage() {
			this.$store.commit('SET_ACTIVE_MENU', '/layout/userInfo')
			this.$router.push({ path: '/layout/userInfo' })
		},
		handleSelect(key, keyPath) {
			console.log(key, keyPath)
		},
		hide() {
			this.showUserInfo = false
			this.isShowMask = false
		},
		login() {
			this.$router.push({ path: '/login', query: { redirect: '/index' } })
		},
		logout() {
			this.$store.dispatch('Logout')
		},
		toBuyNow() {
			this.$router.push({ path: '/buyNow' })
		},
		showLoginInfo() {
			this.showUserInfo = true
			this.isShowMask = true
		},
		freeDownload() {
			this.$router.push({ path: '/downloadFile' })
		},
		getUserInfo() {
			this.$store.commit('GET_USER_INFO')
		},
		getAboutUsData() {
			findAboutUs().then(res => {
				console.log('about', res)
				const list = res || []
				for (let i = 0; i < list.length; i++) {
					if (list[i].type === 'about') {
						const data = list[i].aboutUsRes && list[i].aboutUsRes[0]
						this.aboutUsData = data || {}
						break
					}
				}
			})
			console.log(this.aboutUsData)
		},
		getBannerData() {
			findIndexBanner().then(res => {
				console.log('banner res', res)
				this.bannerData = res || []
			})
		},
		getProductData() {
			findIndexProduct().then(res => {
				this.productData = res || []
			})
		},
		initData() {
			this.getBannerData()
			this.getProductData()
			this.getAboutUsData()
		},
	},
	mounted() {
		this.initData()
		this.getUserInfo()
		const query = this.$route.query
		const main = document.getElementsByClassName('main-content')[0]
		if (query.to === 'product') {
			const height = document.getElementById('product-list').offsetHeight
			main.scrollTo(0, height / 6)
		} else if (query.to === 'aboutus') {
			window.location.hash = '#aboutUs'
		}
	},
}
</script>
<style lang="scss" scoped>
.banner {
	.banner-img {
		width: 100%;
		height: 100%;
	}
	.banner-img-box {
		position: relative;
		height: calc(100vh - 60px);
		line-height: 1.5;
	}
	.banner-cont {
		position: absolute;
		width: 45%;
		// height: 100%;
		left: 10%;
		top: 9%;
		text-align: left;
		color: #fff;
		padding-left: 10%;
		.free-download-btn {
			width: 266px;
			height: 48px;
			color: #2f2b43;
			background-color: linear-gradient(270deg, #ffffff 0%, #dff7ff 48%, #acdeff 100%);
			border-color: rgb(51, 51, 51);
			// font-weight: bold;
			margin-top: 50px;
			border-radius: 12px;
			opacity: 0.7;
			backdrop-filter: blur(2px);
			font-size: 24px;
			border: none;
		}
		.down-icon {
			width: 24px;
			height: 24px;
			background: url(../assets/images/index/down-icon.png) no-repeat;
			background-size: contain;
			display: inline-block;
			vertical-align: middle;
			margin-left: 8px;
			margin-top: -6px;
		}
	}
}

.bg {
	background: linear-gradient(180deg, #dff7ff 0%, #dff7ff 52%, #ffffff 100%);
}
.product-list {
	padding: 30px 20px 30px 20px;
	.product-list-title {
		height: 110px;
		line-height: 110px;
		text-align: center;
		color: rgb(15, 64, 140);
		font-weight: bold;
		font-size: 45px;
	}
	.product-item {
		display: flex;
		justify-content: center;
		margin-top: 75px;
	}

	.first-item {
		margin-top: 30px;
	}
	.product-img-left {
		width: 595px;
		margin-right: 30px;
		height: 340px;
		overflow: hidden;
	}

	.product-img-2 {
		width: 595px;
		margin-left: 30px;
		height: 350px;
		overflow: hidden;
	}

	.product-info {
		width: 35%;
		display: flex;
		align-items: center;
	}
}
.about-us {
	padding-top: 70px;
	display: flex;
	// align-items: center;
	padding-bottom: 80px;
	height: 500px;

	.wrap {
		width: 100%;
	}

	.about-us-title {
		color: #2f2b43;
	}
	.about-us-sub-title {
		color: #2f2b43;
	}
	.list {
		display: flex;
		justify-content: space-around;
		margin-top: 70px;
		width: 100%;
	}
	.box-card {
		width: 30%;
		background: #edf5ff;
		p {
			padding-left: 20px;
		}
	}
	.us-icon-1 {
		width: 47px;
		height: 44px;
	}
	.us-icon-2 {
		width: 44px;
		height: 44px;
	}
	.us-icon-3 {
		width: 51px;
		height: 44px;
	}
}
</style>
<style>
@media screen and (max-width: 1920px) {
	.banner-cont {
		top: 12% !important;
		left: 10% !important;
	}
	.banner-cont .big-font {
		font-size: 62px;
	}
	.banner-cont .spec-font {
		margin-top: 15px;
		font-weight: 700;
		font-size: 65px;
		width: 100%;
		background: linear-gradient(215deg, #ffdcfd 0%, #ffffff 100%);
		-webkit-background-clip: text;
		-webkit-text-fill-color: transparent;
	}
	.banner-cont .sim-font {
		margin-top: 15px;
		font-size: 60px;
		width: 100%;
		background: linear-gradient(207deg, #ffffff 0%, #9bfdff 100%);
		-webkit-background-clip: text;
		-webkit-text-fill-color: transparent;
	}
	.free-download-btn {
		font-size: 24px !important;
	}
	.product-list .product-img-left {
		width: 700px !important;
		margin-right: 30px;
		height: 400px !important;
		overflow: hidden;
	}

	.product-list .product-img-2 {
		width: 700px !important;
		margin-left: 30px;
		height: 400px !important;
		overflow: hidden;
	}
	.about-us {
		padding-top: 70px !important;
		padding-bottom: 60px !important;
		height: 700px !important;
	}
}

@media screen and (max-width: 1680px) {
	.banner-cont {
		top: 10% !important;
		left: 9% !important;
	}
	.banner-cont .big-font {
		font-size: 45px;
	}
	.banner-cont .spec-font {
		margin-top: 15px;
		font-weight: 700;
		font-size: 50px;
		width: 100%;
		background: linear-gradient(215deg, #ffdcfd 0%, #ffffff 100%);
		-webkit-background-clip: text;
		-webkit-text-fill-color: transparent;
	}
	.banner-cont .sim-font {
		margin-top: 15px;
		font-size: 40px;
		width: 100%;
		background: linear-gradient(207deg, #ffffff 0%, #9bfdff 100%);
		-webkit-background-clip: text;
		-webkit-text-fill-color: transparent;
	}
	.free-download-btn {
		width: 230px !important;
		/* height: 38px !important;
		line-height: 38px !important; */
		font-size: 20px !important;
	}
	.about-us {
		padding-top: 70px !important;
		padding-bottom: 60px !important;
		height: 500px !important;
	}
}

@media screen and (max-width: 1366px) {
	.banner-cont {
		top: 12% !important;
		left: 10% !important;
	}
	.banner-cont .big-font {
		font-size: 45px;
	}
	.banner-cont .spec-font {
		margin-top: 15px;
		font-weight: 700;
		font-size: 50px;
		width: 100%;
		background: linear-gradient(215deg, #ffdcfd 0%, #ffffff 100%);
		-webkit-background-clip: text;
		-webkit-text-fill-color: transparent;
	}
	.banner-cont .sim-font {
		margin-top: 15px;
		font-size: 40px;
		width: 100%;
		background: linear-gradient(207deg, #ffffff 0%, #9bfdff 100%);
		-webkit-background-clip: text;
		-webkit-text-fill-color: transparent;
	}
	.free-download-btn {
		width: 230px !important;
		/* height: 38px !important;
		line-height: 38px !important; */
		font-size: 20px !important;
	}

	.about-us {
		padding-top: 70px !important;
		padding-bottom: 60px !important;
		height: 500px;
	}
}
.el-menu.el-menu--horizontal {
	border-bottom: none;
}
.carousel-container .el-carousel__container {
	height: calc(100vh - 60px);
	line-height: 1.5;
	position: relative;
}
.index-page {
	height: 100%;
}
.index-page .el-main {
	padding: 0;
	margin-top: 60px;
}
.el-menu--popup-bottom-start {
	margin-top: 0px;
}
</style>
