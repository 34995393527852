<template>
	<div class="address-view">
		<div class="add-btn-box">
			<el-button type="primary" plain @click="addAddress" v-if="list.length < 10">新增收货地址</el-button>
			<span class="m-l-5"
				>您已创建<span>{{ list.length }}</span> 个收货地址，最多可创建<span>10</span> 个</span
			>
		</div>
		<div class="address-list">
			<div class="address-item" v-for="(item, index) in list" :key="index">
				<div class="receiver-name">
					<h3>{{ item.name }}</h3>
					<h3 class="m-l-5">{{ item.province }}</h3>
					<span class="p-l-10 fs-12 default-icon" v-if="item.isDefault === 1">默认地址</span>
					<span class="del-btn" @click="deleteAddr(item)"><i class="el-icon-close"></i> </span>
				</div>
				<div class="receive-info">
					<div>
						<span class="label">收货人：</span><span class="value">{{ item.name }}</span>
					</div>
					<div>
						<span class="label">所在地区：</span
						><span class="value">{{ item.province }}{{ item.city }}{{ item.area }}</span>
					</div>
					<div>
						<span class="label">地址：</span><span class="value">{{ item.address }}</span>
					</div>
					<div>
						<span class="label">手机：</span><span class="value">{{ item.phone }}</span>
					</div>
					<div>
						<span class="label">固定电话：</span><span class="value">{{ item.tel }}</span>
					</div>
					<div>
						<span class="label">电子邮箱：</span><span class="value">{{ item.email }}</span>
					</div>
					<div class="btn-box">
						<a href="javascript:;" class="default-btn" @click="setDefault(item)" v-if="item.isDefault === 0"
							>设为默认</a
						>
						<a href="javascript:;" @click="edit(item)">编辑</a>
					</div>
				</div>
			</div>
		</div>
		<EditAddress ref="editAddress" :title="title" @finished="handleFinish" :propsData="propsData"></EditAddress>
	</div>
</template>
<script>
import EditAddress from './common/EditAddress.vue'
import { listAddressByUser, setDefaultAddressByUser, deleteAddressByUser } from '@/config/api'
export default {
	name: 'AddressView',
	components: { EditAddress },
	data() {
		return {
			propsData: {},
			title: '',
			list: [
				// {
				// 	name: '刘艳华',
				// 	phone: '135****7308',
				// 	province: '内蒙古',
				// 	city: '通辽市',
				// 	area: '开鲁县',
				// 	address: '大榆树镇榆树村',
				// 	siteCode: '',
				// 	email: '',
				// 	isDefault: '0', // 1 0
				// 	tel: '', // 固定电话
				// 	userName: '',
				// },
			],
		}
	},
	methods: {
		setDefault(item) {
			setDefaultAddressByUser({ id: item.id }).then(() => {
				this.$message.success('设置成功')
				this.getAddressData()
			})
			console.log(item)
		},
		edit(item) {
			this.title = '编辑收货地址'
			this.propsData = {
				item: item,
			}
			this.$refs.editAddress.show()
		},
		deleteAddr(item) {
			this.$confirm('确认删除吗?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning',
			})
				.then(() => {
					deleteAddressByUser({ id: item.id }).then(() => {
						this.$message.success('删除成功')
						this.getAddressData()
					})
				})
				.catch(() => {})
		},
		addAddress() {
			this.propsData = {}
			this.title = '新增收货地址'
			this.$refs.editAddress.show()
		},
		handleFinish() {
			this.getAddressData()
		},
		getAddressData() {
			this.$loading()
			listAddressByUser().then(
				res => {
					this.$loading().close()
					this.list = res || []
				},
				() => {
					this.$loading().close()
				}
			)
		},
	},
	mounted() {
		this.getAddressData()
	},
}
</script>
<style lang="scss" scoped>
.address-view {
	padding: 10px 20px 20px;
}
.add-btn-box {
	padding: 10px 0;
	font-size: 14px;
}
.address-list {
}
.address-item {
	border: 2px solid #e6e6e6;
	margin: 0 0 10px;
	background-color: #fff;
}
.receiver-name {
	padding-left: 10px;
	line-height: 35px;
	height: 35px;
	position: relative;
	display: inline-block;
	display: block;
	overflow: visible;
	h3 {
		float: left;
		color: #666;
	}
	.del-btn {
		float: right;
		margin: 0 10px 0 0;
		cursor: pointer;
	}
	.default-icon {
		background-color: #ffaa45;
		padding: 2px;
		color: #fff;
		margin-left: 10px;
	}
}
.receive-info {
	padding: 10px;
	line-height: 22px;
	// width: 788px;
	position: relative;
	.label {
		width: 70px;
		color: #999;
		text-align: right;
	}
	.value {
		color: #666;
	}
	.btn-box {
		position: absolute;
		right: 10px;
		bottom: 10px;
	}
	.default-btn {
		margin-right: 10px;
	}
}
</style>
