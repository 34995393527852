<template>
	<el-header class="header">
		<div class="mask" v-if="isShowMask" @click="hide"></div>
		<div class="logo-box pointer" @click="toIndex">
			<img class="logo" src="../../assets/images/icons/textAndlogo.png" />
		</div>
		<div class="nav">
			<el-menu
				:default-active="activeIndex"
				active-text-color="#409EFF"
				class="el-menu-custom"
				mode="horizontal"
				@select="handleSelect"
			>
				<el-menu-item index="1" @click="toIndex">首页</el-menu-item>
				<el-menu-item index="2" @click="jumpHash('product')">产品</el-menu-item>
				<el-menu-item index="3" @click="jumpHash('aboutUs')">关于我们</el-menu-item>
				<!-- <el-menu-item index="4" @click="jumpHash('buyNow')">立即购买</el-menu-item> -->
				<el-submenu index="5">
					<template slot="title">技术与支持</template>
					<el-menu-item index="5-1" @click="jumpHash('download')">软件下载</el-menu-item>
					<el-menu-item index="5-2" @click="jumpHash('download')">知识库</el-menu-item>
				</el-submenu>
			</el-menu>
			<div class="login-btn-box">
				<template v-if="!isLogin">
					<el-button class="login-btn" type="primary" size="small" @click="login">登录</el-button>
				</template>
				<template v-else>
					<div class="user-info-box pointer" @click="showLoginInfo">
						<img class="header-avatar" src="../../assets/images/icons/user-avatar.png" />
						<span class="nick-name">{{ userInfo.nickName }}</span
						><i class="triangle-icon"></i>
					</div>
					<div class="hover-cont" v-if="showUserInfo">
						<div class="cont-1">
							<img class="logo-icon" src="../../assets/images/icons/user-avatar.png" />
							<div class="user-info">
								<p>{{ userInfo.nickName }}</p>
								<p class="p-t-5 p-b-5">
									{{ userInfo.phone }} <span class="v-line"></span>
									<el-link @click="toUserInfoPage">设置</el-link>
									<el-link class="m-l-10" @click.stop.prevent="logout">退出</el-link>
								</p>
							</div>
						</div>
						<template v-for="(p, index) in userInfo.daysProductNameSubscribe">
							<div class="user-box-bg" :key="index">
								<div class="text">
									<p>
										{{ p.productName }}
										<!-- <span v-if="userInfo.vip"> / VIP会员 </span> -->
									</p>
									<p v-if="p.endTime">{{ formatEndLine(p.endTime) }}到期</p>
								</div>
								<!-- 隐藏 -->
								<!-- <span class="fee-btn" @click="$router.push('/buyNow')">立即续费</span> -->
							</div>
						</template>
						<div class="line m-t-20 m-b-15"></div>
						<div>
							<template v-for="(item, index) in userInfo.subscribeRes">
								<img :src="item.iconBase64" class="p-icon-1" :key="index" />
							</template>
						</div>
					</div>
				</template>
			</div>
		</div>
	</el-header>
</template>
<script>
import { getUserInfo } from '@/config/api'
export default {
	name: 'CommonHeader',
	props: {
		fromPage: {
			type: String,
			default: '',
		},
	},
	data() {
		return {
			showUserInfo: false,
			isShowMask: false,
		}
	},
	computed: {
		activeIndex() {
			if (this.fromPage === 'index') {
				return '1'
			} else if (this.fromPage === 'buyNow') {
				return '4'
			} else {
				return '5'
			}
		},
		isLogin() {
			console.log('this.$store.state.isLogin', this.$store.state.isLogin)
			return this.$store.state.isLogin
		},
		userInfo() {
			return this.$store.state.userInfo || {}
		},
		endLine() {
			const userInfo = this.$store.state.userInfo || {}
			if (userInfo.recentProductNameSubscribe && userInfo.recentProductNameSubscribe.endTime) {
				const arr = userInfo.recentProductNameSubscribe.endTime.split('-')
				if (arr.length === 3) {
					return arr[0] + '年' + arr[1] + '月' + arr[2] + '日'
				}
			}

			return ''
		},
	},
	methods: {
		formatEndLine(val) {
			if (val) {
				const arr = val.split('-')
				if (arr.length === 3) {
					return arr[0] + '年' + arr[1] + '月' + arr[2] + '日'
				}
				return ''
			}
			return ''
		},
		fromIndex(name) {
			if (name === 'banner') {
				window.location.hash = '#banner'
			} else if (name === 'product') {
				window.location.hash = '#product-list'
			} else if (name === 'aboutUs') {
				window.location.hash = '#aboutUs'
			} else if (name === 'download') {
				this.$router.push({ path: '/downloadFile' })
			} else if (name === 'buyNow') {
				this.$router.push({ path: '/buyNow' })
			}
		},
		fromOther(name) {
			if (name === 'index') {
				this.$router.push({ path: '/index' })
			} else if (name === 'product') {
				this.$router.push({ path: '/index', query: { to: 'product' } })
			} else if (name === 'aboutUs') {
				this.$router.push({ path: '/index', query: { to: 'aboutus' } })
			} else if (name === 'download') {
				this.$router.push({ path: '/downloadFile' })
			} else if (name === 'buyNow') {
				this.$router.push({ path: '/buyNow' })
			}
		},
		jumpHash(name) {
			if (this.fromPage === 'index') {
				this.fromIndex(name)
			} else {
				this.fromOther(name)
			}
		},
		handleSelect() {},
		toIndex() {
			if (this.fromPage === 'index') {
				window.location.hash = '#banner'
			} else {
				this.$router.push('index')
			}
		},
		showLoginInfo() {
			this.showUserInfo = true
			this.isShowMask = true
		},
		hide() {
			this.showUserInfo = false
			this.isShowMask = false
		},
		login() {
			this.$router.push({ path: '/login', query: { redirect: '/index' } })
		},
		logout() {
			this.$store.dispatch('Logout')
		},
		toUserInfoPage() {
			this.$store.commit('SET_ACTIVE_MENU', '/layout/userInfo')
			this.$router.push({ path: '/layout/userInfo' })
		},
		toBuyNow() {
			this.$router.push({ path: '/buyNow' })
		},
		getUserInfo() {
			getUserInfo().then(res => {
				this.$store.commit('SET_USER_INFO', res || {})
			})
		},
	},
	mounted() {
		if (this.$store.state.isLogin) {
			this.getUserInfo()
		}
	},
}
</script>
<style lang="scss" scoped>
.header {
	position: fixed;
	top: 0;
	width: 100%;
	height: 60px !important;
	display: flex;
	z-index: 110;
	background-color: #fff;
	.logo-box {
		width: 35%;
		display: flex;
		align-items: center;
	}
	.logo {
		width: 170px;
		height: 22px;
	}
	.logo-text {
		font-size: 20px;
		color: rgb(51, 51, 51);
		font-weight: bold;
		font-family: 'Microsoft YaHei', 微软雅黑;
		margin-left: 5px;
	}
	.user-info-box {
		display: flex;
		justify-content: center;
		align-items: center;
		margin-right: 10px;
	}
	.nick-name {
		color: #0051ce;
		font-size: 15px;
		margin-left: 10px;
	}

	.triangle-icon {
		display: inline-block;
		width: 10px;
		height: 6px;
		background: url(../../assets/images/icons/triangle@2x.png) no-repeat;
		background-size: contain;
		margin-left: 8px;
	}

	.user-box-bg {
		margin-top: 10px;
		padding: 10px;
		background: url(../../assets/images/icons/user-box-bg.png) no-repeat;
		background-size: 100%;
		display: flex;
		justify-content: space-between;
		font-size: 12px;
		align-items: center;
		color: #fff;

		.text {
			opacity: 0.9;
			text-align: left;
		}
	}

	.fee-btn {
		border-radius: 12px;
		background-color: #fff;
		text-align: center;
		line-height: 20px;
		color: #0051ce;
		font-size: 11px;
		padding: 2px 8px;
		cursor: pointer;
	}

	.el-menu-custom {
		font-weight: bold;
		font-size: 15px;
	}
	.nav {
		display: flex;
		flex: 1;
		justify-content: space-between;
	}
	.login-btn-box {
		text-align: center;
		display: flex;
		justify-content: center;
		align-items: center;
	}
	.header-avatar {
		width: 50px;
		height: 50px;
		border-radius: 25px;
		position: relative;
	}

	.v-line {
		height: 15px;
		width: 1px;
		border-left: 2px dashed #eee;
		margin: 0 10px;
		display: inline-block;
	}

	.hover-cont {
		position: absolute;
		right: 0px;
		top: 60px;
		z-index: 102;
		width: 250px;
		background-color: #fff;
		box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
		padding: 20px 15px 5px 15px;
		border-radius: 5px;
		.cont-1 {
			display: flex;
		}
		.logo-icon {
			width: 50px;
			height: 50px;
		}
		.user-info {
			line-height: 20px;
			font-size: 13px;
			color: #333;
			padding-left: 10px;
			padding-top: 8px;
			text-align: left;
		}
		.line {
			height: 1px;
			background-color: #eee;
		}
	}
	.p-icon-1 {
		width: 35px;
		height: 35px;
		margin-right: 10px;
	}
}
</style>
