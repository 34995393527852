<template>
	<div class="invoice-header-view">
		<div class="btns">
			<el-button type="primay" @click="addInvoiceHeader">添加发票抬头</el-button>
		</div>
		<el-card class="box-card">
			<el-table :data="tableData" stripe style="width: 100%">
				<el-table-column prop="nickName" label="发票抬头">
					<template slot-scope="scope">
						<p v-if="scope.row.billType === 'E'">{{ scope.row.company }}</p>
						<p v-if="scope.row.billType === 'P'">{{ scope.row.company }}</p>
						<p>税号：{{ scope.row.taxNo }}</p>
					</template>
				</el-table-column>
				<el-table-column fixed="right" label="操作" width="250">
					<template slot-scope="scope">
						<el-button type="primary" plain @click="editItem(scope.row)" size="small">编辑</el-button>
						<el-button type="primary" plain @click="deleteItem(scope.row)" class="m-l-5" size="small">删除</el-button>
						<el-button
							type="primary"
							plain
							@click="setDefault(scope.row)"
							class="m-l-5"
							size="small"
							v-if="scope.row.isDefault === 0"
							>设为默认</el-button
						>
					</template>
				</el-table-column>
			</el-table>
		</el-card>
		<EditInvoiceHeader
			ref="editInvoiceHeader"
			:title="title"
			@finished="refreshTable"
			:propsData="propsData"
		></EditInvoiceHeader>
	</div>
</template>
<script>
import EditInvoiceHeader from './common/EditInvoiceHeader.vue'
import { getInvoiceHeaderList, delInvoiceHeader, setDefaultInvoiceHeader } from '@/config/api.js'
export default {
	name: 'InvoiceHeaderView',
	components: { EditInvoiceHeader },
	data() {
		return {
			propsData: {},
			tableData: [],
			title: '',
		}
	},
	methods: {
		addInvoiceHeader() {
			this.propsData = {}
			this.title = '添加发票抬头'
			this.$refs.editInvoiceHeader.show()
		},
		deleteItem(item) {
			this.$confirm('确认删除吗?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning',
			})
				.then(() => {
					delInvoiceHeader({ id: item.id }).then(() => {
						this.$message.success('删除成功')
						this.getTableData()
					})
				})
				.catch(() => {})
		},
		editItem(item) {
			this.propsData = {
				item: { ...item },
			}
			this.title = '编辑发票抬头'
			this.$refs.editInvoiceHeader.show()
		},
		setDefault(item) {
			setDefaultInvoiceHeader({ id: item.id }).then(() => {
				this.$message.success('设置成功')
				this.getTableData()
			})
		},
		refreshTable() {
			this.getTableData()
		},
		getTableData() {
			this.$loading()
			getInvoiceHeaderList().then(
				res => {
					this.$loading().close()
					this.tableData = res
				},
				() => {
					this.$loading().close()
				}
			)
		},
	},
	mounted() {
		this.getTableData()
	},
}
</script>
<style lang="scss" scoped>
.btns {
	margin-bottom: 10px;
}
.user-pic {
	display: inline-block;
	vertical-align: middle;
	margin-right: 10px;
}
</style>
