<template>
	<div class="edit-user-modal">
		<el-dialog :title="title || '编辑收货地址'" :visible.sync="dialogVisible" width="50%" :close-on-click-modal="false">
			<el-form :model="ruleForm" ref="ruleForm" label-width="100px" class="rule-form">
				<el-form-item prop="name" label="收货人" :rules="[{ required: true, message: '请输入', trigger: 'blur' }]">
					<el-input v-model="ruleForm.name" placeholder="请填写收货人姓名"></el-input>
				</el-form-item>
				<el-form-item
					prop="siteCode"
					label="所在地区"
					:rules="[{ required: true, message: '请选择', trigger: 'change' }]"
				>
					<el-cascader
						v-model="ruleForm.siteCode"
						:options="AddrData"
						@change="handleChange"
						style="width: 100%"
					></el-cascader>
				</el-form-item>
				<el-form-item prop="address" label="详细地址" :rules="[{ required: true, message: '请输入', trigger: 'blur' }]">
					<el-input v-model="ruleForm.address" placeholder="请填写收货人详细地址"></el-input>
				</el-form-item>
				<el-form-item prop="phone" label="手机号" :rules="[{ required: true, message: '请输入', trigger: 'blur' }]">
					<el-input v-model="ruleForm.phone" placeholder="请填写收货人手机号码" :maxLength="11"></el-input>
				</el-form-item>
				<el-form-item prop="tel" label="固定电话">
					<el-input v-model="ruleForm.tel" placeholder="请填写收货人固定电话"></el-input>
				</el-form-item>
				<el-form-item prop="email" label="邮箱地址">
					<el-input v-model="ruleForm.email" placeholder="请填写收货人邮箱地址"></el-input>
				</el-form-item>
			</el-form>
			<span slot="footer" class="dialog-footer">
				<el-button @click="handleCancel">取 消</el-button>
				<el-button type="primary" @click="submitForm">确 定</el-button>
			</span>
		</el-dialog>
	</div>
</template>
<script>
import { getCityData, createAddressByUser, updateUserAddressByUser } from '@/config/api.js'
// import CityData from './city.js'
export default {
	name: 'EditAddress',
	components: {},
	props: {
		title: {
			type: String,
			default: '',
		},
		propsData: {
			type: Object,
			default: () => {
				return {}
			},
		},
	},
	data() {
		return {
			dialogVisible: false,
			ruleForm: { siteCode: '' },
			formName: 'ruleForm',
			AddrData: [],
		}
	},
	methods: {
		show() {
			this.dialogVisible = true
		},
		handleChange(data) {
			console.log('data', data)
			const list = data || []
			this.ruleForm.siteCode = list[list.length - 1]
			console.log('this.ruleForm.siteCode', this.ruleForm.siteCode)
		},
		getCityData() {
			getCityData().then(res => {
				console.log('getCityData res', this.AddrData)
				this.AddrData = res || []
			})
		},
		handleCancel() {
			this.dialogVisible = false
			this.resetForm()
		},
		resetForm() {
			this.$refs[this.formName].resetFields()
		},
		submitForm() {
			this.$refs[this.formName].validate(valid => {
				if (valid) {
					if (this.propsData.item) {
						this.editSubmit()
					} else {
						this.addSubmit()
					}
				} else {
					console.log('error submit!!')
					return false
				}
			})
		},
		addSubmit() {
			const params = {
				address: this.ruleForm.address,
				email: this.ruleForm.email,
				isDefault: this.ruleForm.isDefault,
				name: this.ruleForm.name,
				phone: this.ruleForm.phone,
				siteCode: this.ruleForm.siteCode,
				tel: this.ruleForm.tel,
			}
			this.$loading()
			createAddressByUser(params).then(
				() => {
					this.$loading().close()
					this.$message.success('添加成功')
					this.handleCancel()
					this.$emit('finished')
				},
				() => {
					this.$loading().close()
				}
			)
		},
		editSubmit() {
			const params = {
				address: this.ruleForm.address,
				email: this.ruleForm.email,
				id: this.ruleForm.id,
				// isDefault: this.ruleForm.isDefault,
				name: this.ruleForm.name,
				phone: this.ruleForm.phone,
				siteCode: this.ruleForm.siteCode,
				tel: this.ruleForm.tel,
			}
			this.$loading()
			updateUserAddressByUser(params).then(
				() => {
					this.$loading().close()
					this.$message.success('添加成功')
					this.handleCancel()
					this.$emit('finished')
				},
				() => {
					this.$loading().close()
				}
			)
		},
	},
	watch: {
		dialogVisible() {
			if (this.dialogVisible) {
				if (this.propsData.item) {
					this.ruleForm = this.propsData.item
					console.log('this.ruleForm', this.ruleForm)
				} else {
					this.ruleForm = {}
				}
			}
		},
	},
	mounted() {
		this.getCityData()
	},
}
</script>
<style lang="scss" scoped>
.address-box {
	position: relative;
	.addr-sec {
		position: absolute;
		top: 42px;
		left: 0;
		width: 100%;
		z-index: 2;
	}
}
</style>
