<template>
	<el-container class="buy-now-view">
		<common-header fromPage="buyNow"></common-header>
		<div class="bg">
			<el-main class="main-content">
				<div class="my-order" @click="toMyOrder">我的订单<span class="arrow-right"></span></div>
				<div class="login-cont">
					<div class="login-left">
						<p><span>翌兽AI</span>收银台</p>
						<p class="text">快人一步，助您易售</p>
					</div>
					<div class="login-box pointer" @click="toUserInfoPage">
						<template v-if="!isLogin">
							<img class="avatar" src="../assets/images/icons/avatar.png" />
							<p class="login-btn">立即登录</p>
							<p class="text">您还没有登录哦！</p>
						</template>
						<template v-else>
							<img class="avatar" src="../assets/images/icons/user-avatar.png" />
							<p class="login-btn">{{ userInfo.nickName }}</p>
							<p class="text">{{ secretPhone }}</p>
						</template>
						<div class="dot-line"></div>
						<template v-for="(arr, oindex) in userInfo.subscribeRes">
							<div class="product-icons" :class="{ 'flex-start': oindex > 0 }" :key="oindex">
								<template v-for="(item, index) in arr">
									<img :src="item.iconBase64" class="product-icon" :key="index" />
								</template>
							</div>
						</template>
						<!-- <div class="product-icons">
							<template v-for="(item, index) in userInfo.subscribeRes">
								<img :src="item.iconBase64" class="product-icon" :key="index" />
							</template>
						</div> -->
					</div>
				</div>

				<div class="product-one" v-for="(s, index) in productSeriesList" :key="index">
					<div class="product-title">
						{{ s.seriesName }}
						<div class="color-line"></div>
					</div>
					<!-- <div class="products">
						<template v-for="(item, index) in s.productResList">
							<template v-if="index < 4">
								<div class="product-item" :key="index">
									<div class="package-icon" v-if="item.mustBought > 0">
										<p class="fs-13">{{ item.mustBought }}人</p>
										<p class="fs-13">套餐</p>
									</div>
									<img class="product-icon" :src="siteUrl + item.icon" />
									<span class="prodct-title">{{ item.name }}</span>
									<div v-html="item.description"></div>
									<div class="buy-btn" @click="goToBuy(item)">
										立即购买<img class="blue-arrow" src="../assets/images/icons/blue-arrow.png" />
									</div>
								</div>
							</template>
						</template>
					</div> -->
					<!-- <div class="products-2">
						<template v-for="(item, index) in s.productResList">
							<template v-if="index >= 4">
								<div class="product-item" :key="index">
									<div class="package-icon" v-if="item.mustBought > 0">
										<p class="fs-13">{{ item.mustBought }}人</p>
										<p class="fs-13">套餐</p>
									</div>
									<img class="product-icon" :src="item.icon" />
									<span class="prodct-title">{{ item.name }}</span>
									<div v-html="item.description"></div>
									<div class="buy-btn" @click="goToBuy(item)">
										立即购买<img class="blue-arrow" src="../assets/images/icons/blue-arrow.png" />
									</div>
								</div>
							</template>
						</template>
					</div> -->
					<template v-for="(arr, i) in s.productResList">
						<div class="products-2" :key="i">
							<div class="product-item" v-for="(item, ii) in arr" :key="ii">
								<div class="package-icon" v-if="item.mustBought > 0">
									<p class="fs-13">{{ item.mustBought }}人</p>
									<p class="fs-13">套餐</p>
								</div>
								<img class="product-icon" :src="item.icon" />
								<span class="prodct-title">{{ item.name }}</span>
								<div v-html="item.description"></div>
								<div class="buy-btn" @click="goToBuy(item)">
									立即购买<img class="blue-arrow" src="../assets/images/icons/blue-arrow.png" />
								</div>
							</div>
						</div>
					</template>
				</div>
				<!-- <div class="product-one">
					<div class="product-title">
						<div>AI描稿系列</div>
						<div class="color-line"></div>
					</div>
					<div class="products">
						<template v-for="(item, index) in aiSketching">
							<template v-if="index < 4">
								<div class="product-item" :key="index">
									<i class="package-icon-3" v-if="item.mustBought === 3"></i>
									<i class="package-icon-5" v-if="item.mustBought === 5"></i>
									<img class="product-icon" :src="item.icon" />
									<span class="prodct-title">{{ item.name }}</span>
									<div v-html="item.description"></div>
									<div class="buy-btn" @click="goToBuy(item)">
										立即购买<img class="blue-arrow" src="../assets/images/icons/blue-arrow.png" />
									</div>
								</div>
							</template>
						</template>
					</div>
					<div class="products-2">
						<template v-for="(item, index) in aiSketching">
							<template v-if="index > 4">
								<div class="product-item" :key="index">
									<i class="package-icon-3" v-if="item.mustBought === 3"></i>
									<i class="package-icon-5" v-if="item.mustBought === 5"></i>
									<img class="product-icon" :src="item.icon" />
									<span class="prodct-title">{{ item.name }}</span>
									<div v-html="item.description"></div>
									<div class="buy-btn" @click="goToBuy(item)">
										立即购买<img class="blue-arrow" src="../assets/images/icons/blue-arrow.png" />
									</div>
								</div>
							</template>
						</template>
					</div>
				</div>
				<div class="product-one">
					<div class="product-title">
						<div>其他</div>
						<div class="color-line"></div>
					</div>
					<div class="products">
						<template v-for="(item, index) in dongle">
							<div class="product-item" :key="index">
								<img class="product-icon" :src="item.icon" />
								<span class="prodct-title">{{ item.name }}</span>
								<div v-html="item.description"></div>
								<div class="buy-btn" @click="goToBuy(item)">
									立即购买<img class="blue-arrow" src="../assets/images/icons/blue-arrow.png" />
								</div>
							</div>
						</template>
					</div>
				</div> -->
				<div class="wish-tip">更多AI功能正在路上，敬请期待！</div>
			</el-main>
			<common-footer></common-footer>
		</div>
		<el-backtop :bottom="50" :visibility-height="100"></el-backtop>
	</el-container>
</template>
<script>
import { listProduct, getUserInfo } from '@/config/api'
export default {
	name: 'BuyNowView',
	data() {
		return {
			aiDraw: [], // ai调色
			aiSketching: [], // ai描稿
			dongle: [], // 加密狗
			selectedDongle: {},
			productSeriesList: [],
		}
	},
	computed: {
		isLogin() {
			return this.$store.state.isLogin
		},
		secretPhone() {
			return this.$util.toSecretPhone(this.userInfo.phone)
		},
		userInfo() {
			let rList = []
			if (this.$store.state.userInfo.subscribeRes) {
				let list = [...this.$store.state.userInfo.subscribeRes]
				for (let i = 0; i < list.length; i += 6) {
					let arr = []
					if (i + 5 < list.length) {
						arr = list.slice(i, i + 6)
					} else {
						arr = list.slice(i)
					}
					rList.push(arr)
				}
			}
			const info = {
				...this.$store.state.userInfo,
				subscribeRes: rList,
			}
			return info || {}
		},
		siteUrl() {
			if (process.env.NODE_ENV === 'development') {
				return 'http://175.27.250.166:8080'
			} else {
				return ''
			}
		},
	},
	methods: {
		login() {
			this.$router.push({ path: '/login', query: { redirict: '/buyNow' } })
		},
		goToBuy(item) {
			this.$router.push({ path: '/order', query: { productId: item.id } })
		},
		toMyOrder() {
			this.$store.commit('SET_ACTIVE_MENU', '/layout/orderList')
			this.$router.push({ path: '/layout/orderList' })
		},
		toUserInfoPage() {
			this.$store.commit('SET_ACTIVE_MENU', '/layout/userInfo')
			this.$router.push({ path: '/layout/userInfo' })
		},
		getUserInfo() {
			getUserInfo().then(res => {
				this.$store.commit('SET_USER_INFO', res || {})
			})
		},
		selectDongleItem(item) {
			this.selectedDongle = item
		},
		getProductInfo() {
			this.$loading()
			listProduct().then(
				res => {
					this.$loading().close()
					// this.aiDraw = res.aidraw || []
					// this.aiSketching = res.aisketching || []
					// this.dongle = res.dongle || []
					this.productSeriesList = res || []
					this.productSeriesList = this.productSeriesList.map(item => {
						const rList = []
						const productResList = [...item.productResList]
						for (let i = 0; i < productResList.length; i += 4) {
							let arr = []
							if (i + 3 < productResList.length) {
								arr = productResList.slice(i, i + 4)
							} else {
								arr = productResList.slice(i)
							}
							rList.push(arr)
						}
						return {
							...item,
							productResList: rList,
						}
					})
					console.log('this.productSeriesList', this.productSeriesList)
				},
				() => {
					this.$loading().close()
				}
			)
		},
	},
	mounted() {
		this.getUserInfo()
		this.getProductInfo()
	},
}
</script>
<style lang="scss" scoped>
.bg {
	// background: linear-gradient(180deg, #acdeff 0%, #dff7ff 52%, #ffffff 100%);
	background: url(../assets/images/buynow/bg.png) no-repeat;
	background-size: 100%;
	margin-top: 56px;
	width: 100%;
}

.main-content {
	width: 1220px;
	margin: 0 auto;
	padding-bottom: 80px;
}

.my-order {
	padding-top: 30px;
	padding-right: 80px;
	text-align: right;
	font-size: 16px;
	line-height: 20px;
	color: #0051ce;
	cursor: pointer;

	.arrow-right {
		display: inline-block;
		width: 20px;
		height: 20px;
		vertical-align: middle;
		background: #fff url(../assets/images/icons/arrow-right.png) no-repeat;
		background-size: 100%;
		border-radius: 10px;
		margin-left: 5px;
		margin-top: -4px;
	}
}

.login-cont {
	display: flex;
	justify-content: space-between;
	// height: 266px;
	padding-top: 70px;
	padding-left: 80px;
	padding-right: 80px;
	overflow: hidden;
}

.login-left {
	padding-top: 20px;
	font-size: 46px;
	color: #2f2b43;
	line-height: 65px;

	span {
		font-size: 46px;
		font-weight: 600;
		line-height: 77px;
		background: linear-gradient(360deg, #0051ce 0%, #1ca3fd 100%);
		-webkit-background-clip: text;
		-webkit-text-fill-color: transparent;
		padding-right: 20px;
	}

	.text {
		line-height: 65px;
		color: #2f2b43;
		letter-spacing: 2px;
	}
}

.login-box {
	width: 400px;
	// height: 252px;
	background: #ffffff;
	box-shadow: 0px 0px 22px 0px rgba(40, 186, 255, 0.5);
	border-radius: 16px;
	opacity: 0.9;
	backdrop-filter: blur(50px);
	position: relative;
	padding-bottom: 15px;

	.avatar {
		width: 87px;
		height: 87px;
		position: absolute;
		top: -41px;
		left: calc(200px - 44px);
	}

	.login-btn {
		font-size: 20px;
		color: #0051ce;
		line-height: 40px;
		cursor: pointer;
		text-align: center;
		padding-top: 60px;
	}

	.text {
		font-size: 15px;
		color: #191919;
		line-height: 20px;
		text-align: center;
	}

	.dot-line {
		width: 300px;
		border-style: dashed;
		border-width: 1px;
		border-color: #d4d4d4;
		border-dasharray: 5px 10px;
		margin: 20px auto 10px auto;
	}

	.product-icons {
		display: flex;
		justify-content: space-around;
		// margin: 0 auto;
		padding: 5px 10px 0 10px;
		// width: 270px;
	}

	.flex-start {
		justify-content: flex-start;
	}

	.product-icon {
		width: 60px;
		height: 60px;
	}
}

.package-icon {
	position: absolute;
	top: -5px;
	right: -8px;
	width: 88px;
	height: 99px;
	background: url(../assets/images/icons/package-icon.png) no-repeat;
	background-size: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding-left: 10px;
	color: #db5f17;
	font-weight: bold;
}

.package-icon-5 {
	position: absolute;
	top: -5px;
	right: -8px;
	width: 88px;
	height: 99px;
	background: url(../assets/images/buynow/package-5-icon.png) no-repeat;
	background-size: 100%;
}

.product-one {
	padding-top: 30px;

	.product-title {
		font-size: 48px;
		color: #111111;
		line-height: 67px;
		text-align: center;

		.color-line {
			width: 120px;
			height: 2px;
			margin: 20px auto 0 auto;
			background: linear-gradient(
				270deg,
				#e02020 0%,
				#fa6400 17%,
				#f7b500 33%,
				#6dd400 50%,
				#0091ff 67%,
				#6236ff 83%,
				#b620e0 100%
			);
		}
	}

	.products {
		display: flex;
		padding-top: 40px;
		justify-content: center;
	}

	.products-2 {
		display: flex;
		padding-top: 40px;
	}

	.product-item {
		width: 290px;
		background: #ffffff;
		border-radius: 20px;
		display: flex;
		flex-direction: column;
		align-items: center;
		padding-top: 40px;
		margin-right: 20px;
		position: relative;
		box-sizing: border-box;
		padding-bottom: 40px;

		.product-icon {
			width: 80px;
			height: 80px;
		}

		.prodct-title {
			font-size: 20px;
			color: #202020;
			margin-top: 15px;
		}

		.name-1 {
			display: inline-block;
			width: 28px;
			height: 28px;
			margin-right: 20px;
			vertical-align: middle;
		}

		.buy-btn {
			width: 166px;
			height: 42px;
			line-height: 42px;
			background: #0051ce;
			border-radius: 26px;
			position: relative;
			color: #ffffff;
			font-size: 16px;
			text-align: center;
			margin-top: 10px;
			cursor: pointer;
		}

		.btn-2 {
			background: #ffb271;
		}

		.btn-3 {
			width: 196px;
			background: #a760ff;
		}

		.btn-4 {
			width: 196px;
			background: #64dfda;
		}

		.blue-arrow {
			position: absolute;
			top: 8px;
			right: 8px;
			width: 25px;
			height: 25px;
			background-color: #ffffff;
			border-radius: 16px;
		}
	}
}

// .product-two {
// 	padding-top: 50px;
// 	padding-bottom: 40px;
// 	.product-two-title {
// 		font-size: 48px;
// 		color: #111111;
// 		line-height: 67px;
// 		text-align: center;
// 		.color-line {
// 			width: 120px;
// 			height: 2px;
// 			margin: 20px auto 0 auto;
// 			background: linear-gradient(
// 				270deg,
// 				#e02020 0%,
// 				#fa6400 17%,
// 				#f7b500 33%,
// 				#6dd400 50%,
// 				#0091ff 67%,
// 				#6236ff 83%,
// 				#b620e0 100%
// 			);
// 		}
// 	}
// 	.products {
// 		display: flex;
// 		padding-top: 40px;
// 		justify-content: center;
// 	}
// 	.product-item {
// 		width: 350px;
// 		height: 404px;
// 		background: #ffffff;
// 		border-radius: 24px;
// 		display: flex;
// 		flex-direction: column;
// 		align-items: center;
// 		padding-top: 30px;
// 		padding-left: 20px;
// 		padding-right: 20px;
// 		margin-right: 20px;
// 		padding-bottom: 20px;
// 		position: relative;
// 		.product-icon {
// 			width: 80px;
// 			height: 80px;
// 		}
// 		.prodct-title {
// 			font-size: 20px;
// 			color: #202020;
// 			margin-top: 10px;
// 		}
// 		.product-img {
// 			width: 300px;
// 			height: 200px;
// 			border-radius: 10px;
// 			margin-top: 20px;
// 		}
// 		.buy-btn {
// 			width: 166px;
// 			height: 42px;
// 			line-height: 42px;
// 			background: #0051ce;
// 			border-radius: 26px;
// 			position: relative;
// 			color: #ffffff;
// 			font-size: 16px;
// 			text-align: center;
// 			margin-top: 20px;
// 		}
// 		.btn-3 {
// 			width: 196px;
// 			background: #a760ff;
// 		}
// 		.btn-4 {
// 			width: 196px;
// 			background: #64dfda;
// 		}
// 		.blue-arrow {
// 			position: absolute;
// 			top: 8px;
// 			right: 8px;
// 			width: 25px;
// 			height: 25px;
// 			background-color: #ffffff;
// 			border-radius: 16px;
// 		}
// 	}
// }
// .product-other {
// 	padding-top: 30px;
// 	.product-other-title {
// 		font-size: 48px;
// 		color: #111111;
// 		line-height: 67px;
// 		text-align: center;
// 		.color-line {
// 			width: 120px;
// 			height: 2px;
// 			margin: 20px auto 0 auto;
// 			background: linear-gradient(
// 				270deg,
// 				#e02020 0%,
// 				#fa6400 17%,
// 				#f7b500 33%,
// 				#6dd400 50%,
// 				#0091ff 67%,
// 				#6236ff 83%,
// 				#b620e0 100%
// 			);
// 		}
// 	}
// 	.product {
// 		height: 450px;
// 		background: #ffffff;
// 		border-radius: 24px;
// 		margin: 0 auto;
// 		margin-top: 20px;
// 		.lock {
// 			padding: 30px 40px 40px 40px;
// 			display: flex;
// 			justify-content: space-between;
// 		}
// 		.lock-icon {
// 			width: 80px;
// 			height: 80px;
// 			display: inline-block;
// 			vertical-align: middle;
// 			margin-right: 10px;
// 		}
// 		.lock-left {
// 			span {
// 				font-size: 32px;
// 				color: #202020;
// 				display: inline-block;
// 				vertical-align: middle;
// 			}
// 		}
// 		.buy-btn {
// 			width: 205px;
// 			height: 64px;
// 			background: #ff6b6b;
// 			border-radius: 32px;
// 			line-height: 64px;
// 			text-align: center;
// 			color: #ffffff;
// 			font-size: 24px;
// 			position: relative;
// 		}
// 		.arrow {
// 			position: absolute;
// 			top: 15px;
// 			right: 8px;
// 			width: 30px;
// 			height: 30px;
// 			background-color: #ffffff;
// 			border-radius: 16px;
// 		}
// 		.product-list {
// 			display: flex;
// 			justify-content: space-between;
// 			padding: 0 20px 40px 20px;
// 			.other-product-img {
// 				width: 522px;
// 				height: 288px;
// 			}
// 		}
// 	}
// }
.wish-tip {
	height: 80px;
	line-height: 80px;
	text-align: center;
	color: #333333;
	opacity: 0.3;
	font-size: 20px;
	padding-bottom: 20px;
	padding-top: 30px;
}
</style>
<style>
.el-menu.el-menu--horizontal {
	border-bottom: none;
}

.buy-now-view .el-main {
	padding: 0;
}

.el-menu--popup-bottom-start {
	margin-top: 0px;
}

.product-name {
	font-size: 15px;
	color: #333333;
	line-height: 32px;
	padding: 10px 0;

	.text {
		display: inline-block;
		/* width: 64px; */
		text-align: left;
	}
}

.name-icon {
	display: inline-block;
	width: 28px;
	height: 28px;
	margin-right: 20px;
	vertical-align: middle;
}
</style>
