<template>
	<div class="login-view">
		<header class="header">
			<div class="header-left pointer" @click="$router.push({ path: '/index' })">
				<img src="../assets/images/icons/textAndlogo.png" class="logo" />
			</div>
		</header>
		<div class="login-box">
			<el-card class="box-card">
				<div class="login-container">
					<div class="text-center">
						<img class="logo-icon" src="../assets/images/icons/textAndlogo.png" />
					</div>
					<div class="login-form" :class="{ pt10: errorMsg }">
						<div class="error" v-show="errorMsg">
							{{ errorMsg }}
						</div>
						<div class="form-item">
							<p class="fs-12 font-bold">账号</p>
							<el-input class="input-text" v-model="userName" placeholder="请输入手机号"></el-input>
						</div>
						<div class="form-item m-t-10">
							<p class="fs-12 font-bold">密码</p>
							<el-input class="input-text" v-model="password" placeholder="请输入密码" show-password></el-input>
						</div>
						<div class="password-set">
							<el-checkbox class="check-box" v-model="checked">记住密码</el-checkbox>
							<span class="fs-10 forger-pswd" @click="$router.push({ path: '/forgotPswd' })">忘记密码？</span>
						</div>
						<div class="login-btn-box">
							<el-button class="login-btn" type="primary" @click="login">登 录</el-button>
						</div>
					</div>
					<div class="create-account">
						<span class="fs-12">没有账户？</span
						><span><router-link :to="{ path: '/register', query: query }">创建一个</router-link></span>
					</div>
				</div>
				<img class="login-img" src="../assets/images/login-img.png" />
			</el-card>
		</div>
		<copy-right></copy-right>
	</div>
</template>
<script>
import { login, getUserInfo, replaceCreateBy } from '@/config/api'

export default {
	name: 'LoginView',
	data() {
		return {
			userName: '',
			password: '',
			checked: false,
			errorMsg: '',
			userInfo: {},
			query: {},
		}
	},
	methods: {
		getUserData() {
			getUserInfo().then(res => {
				this.userInfo = res || {}
				this.$store.commit('SET_USER_INFO', this.userInfo)
				const redirect = this.$route.query.redirect
				console.log(redirect)
				this.$router.push(redirect || '/index')
			})
		},

		login() {
			if (!this.userName) {
				this.errorMsg = '请输入帐户名'
				return
			}
			if (!this.password) {
				this.errorMsg = '请输入密码'
				return
			}
			if (this.userName && this.password) {
				this.errorMsg = ''
			}
			const params = {
				userName: this.userName,
				password: this.password,
			}
			login(params).then(res => {
				localStorage.setItem('token', res.token)
				this.$store.commit('setIsLogin', true)

				console.log('this.query', this.query)
				if (this.query && this.query.createBy) {
					const msg = '【' + this.query.name + '】即将为您服务，是否接受邀请？'
					this.$confirm(msg, '确认信息', {
						distinguishCancelAndClose: true,
						confirmButtonText: '确定',
						cancelButtonText: '取消',
					})
						.then(() => {
							const params = {
								createBy: this.query.createBy,
								userName: this.userName,
							}
							replaceCreateBy(params).then(() => {
								this.$message.success('感谢您选择' + this.query.name + '为您服务')
								this.getUserData()
							})
						})
						.catch(() => {
							this.$message({
								type: 'info',
								message: '取消选择服务商',
							})
							this.getUserData()
						})
				} else {
					this.getUserData()
				}
			})
		},
	},
	mounted() {
		const query = this.$route.query
		this.query = query
		console.log('mounted', this.query)
	},
}
</script>
<style lang="scss" scoped>
.login-view {
	width: 100%;
	background: url(../assets/images/banner-bg.png) no-repeat;
	background-size: 100%;
	position: relative;
}

.login-box {
	display: flex;
	justify-content: center;
	align-items: center;
	height: calc(100vh - 60px);
}
.header {
	height: 60px;
	padding: 0 30px;
	display: flex;
	justify-content: space-between;
	width: 1225px;
	margin: 0 auto;
	.header-left {
		height: 60px;
		display: flex;
		align-items: center;
		.logo {
			width: 180px;
			height: 22px;
			margin-right: 5px;
		}
	}
	.header-right {
		height: 60px;
		display: flex;
		align-items: center;
		width: 60px;
		text-align: center;
		justify-content: center;
	}
}
.box-card {
	overflow: hidden;
	display: flex;
}
.login-container {
	width: 55%;
	height: 100%;
	padding-top: 30px;
	.logo-icon {
		width: 180px;
	}
}
.error {
	height: 25px;
	line-height: 25px;
	background-color: #fef2f2;
	border-color: #ffb4a8;
	border: 1px solid #ff934c;
	color: #6c6c6c;
	font-size: 12px;
	padding-left: 20px;
	margin-bottom: 10px;
}
.login-form {
	padding: 25px 40px 20px 40px;
}
.input-text {
	border: none;
}
.password-set {
	padding-top: 20px;
	display: flex;
	justify-content: space-between;
	align-items: center;
}
.forger-pswd {
	padding-right: 15px;
	cursor: pointer;
}
.login-btn-box {
	margin-top: 25px;
	text-align: center;
}
.login-btn {
	width: 180px;
	background: url(../assets/images/login-btn-bg.png) no-repeat;
	background-size: 100%;
	border: none;
}
.login-img {
	height: 100%;
}
.create-account {
	text-align: center;
	font-size: 10px;
	a {
		text-decoration: underline;
		cursor: pointer;
	}
}
.pt10 {
	padding-top: 10px;
}
</style>
<style>
.login-view .el-card__body {
	width: 780px;
	height: 500px;
	padding: 0;
	display: flex;
}
.login-view .el-input__inner {
	border: none;
	height: 35px;
	line-height: 35px;
	border-bottom: 1px solid #eee;
}
.login-view .el-checkbox__inner {
	width: 12px;
	height: 12px;
}
.login-view .el-checkbox__label {
	font-size: 10px;
}

.login-view .copy-right {
	position: absolute;
	bottom: 0;
	left: 0;
}
</style>
