import Vue from 'vue'
import Vuex from 'vuex'
import { logout } from '@/config/api'
import router from '../router'

Vue.use(Vuex)

export default new Vuex.Store({
	state: {
		token: localStorage.getItem('token'),
		isLogin: localStorage.getItem('token') ? true : false,
		userInfo: '',
		activeMenu: '',
		active: 0,
	},
	getters: {},
	mutations: {
		setIsLogin(state, isLogin) {
			state.isLogin = isLogin
		},
		SET_USER_INFO(state, userInfo) {
			localStorage.setItem('USER_INFO', JSON.stringify(userInfo))
			state.userInfo = userInfo
		},
		GET_USER_INFO(state) {
			let userInfo = {}
			try {
				userInfo = JSON.parse(localStorage.getItem('USER_INFO'))
				state.userInfo = userInfo
			} catch {
				state.userInfo = userInfo
			}
		},
		SET_ACTIVE_MENU(state, menuPath) {
			state.activeMenu = menuPath
			sessionStorage.setItem('activeMenu', menuPath)
		},
		SET_ACTIVE_INDEX(state, index) {
			state.active = index
			sessionStorage.setItem('next', index)
		},
	},
	actions: {
		Logout({ commit }) {
			console.log(router.history.current.fullPath)
			logout().then(() => {
				commit('setIsLogin', false)
				// localStorage.removeItem('token')
				// localStorage.removeItem('USER_INFO')
				localStorage.clear()
				const history = router.history.current.fullPath
				router.push({ path: '/login', query: { redirect: history } })
			})
		},
	},
	modules: {},
})
