<template>
	<div class="edit-user-modal">
		<el-dialog title="添加成员" :visible.sync="dialogVisible" width="30%" :close-on-click-modal="false">
			<el-form :model="ruleForm" ref="ruleForm" label-width="100px" class="rule-form">
				<el-form-item prop="nickName" label="姓名" :rules="[{ required: true, message: '请输入', trigger: 'blur' }]">
					<el-input v-model="ruleForm.nickName" placeholder="请输入姓名" autocomplete="off"></el-input>
				</el-form-item>
				<el-form-item
					prop="account"
					label="账号"
					:rules="[{ required: true, validator: validateAccount, trigger: 'blur' }]"
				>
					<el-input v-model="ruleForm.account" placeholder="请输入账号" autocomplete="off"></el-input>
				</el-form-item>
				<el-form-item
					prop="password"
					label="密码"
					:rules="[{ required: true, validator: validatePass, trigger: 'blur' }]"
				>
					<el-input
						type="password"
						v-model="ruleForm.password"
						autocomplete="off"
						placeholder="包含字母、数字或符号，至少2种， 不少于8位"
						:maxLength="32"
					></el-input>
				</el-form-item>
				<el-form-item
					prop="confirmPassword"
					label="确认密码"
					:rules="[{ required: true, validator: validateConfirmPass, trigger: 'blur' }]"
				>
					<el-input
						type="password"
						v-model="ruleForm.confirmPassword"
						autocomplete="off"
						placeholder="包含字母、数字或符号，至少2种， 不少于8位"
						:maxLength="32"
					></el-input>
				</el-form-item>
				<!-- <el-form-item
					prop="email"
					label="邮箱"
					:rules="[
						{ required: true, message: '请输入邮箱地址', trigger: 'blur' },
						{ type: 'email', message: '请输入正确的邮箱地址', trigger: ['blur', 'change'] },
					]"
				>
					<el-input v-model="dynamicValidateForm.email"></el-input>
				</el-form-item> -->
				<!-- <el-form-item
					v-for="(domain, index) in dynamicValidateForm.domains"
					:label="'域名' + index"
					:key="domain.key"
					:prop="'domains.' + index + '.value'"
					:rules="{
						required: true,
						message: '域名不能为空',
						trigger: 'blur',
					}"
				>
					<el-input v-model="domain.value"></el-input><el-button @click.prevent="removeDomain(domain)">删除</el-button>
				</el-form-item> -->
			</el-form>
			<span slot="footer" class="dialog-footer">
				<el-button @click="handleCancel">取 消</el-button>
				<el-button type="primary" @click="submitForm">确 定</el-button>
			</span>
		</el-dialog>
	</div>
</template>
<script>
import { addTeamUser } from '@/config/api.js'
export default {
	name: 'EditUser',
	data() {
		return {
			dialogVisible: false,
			ruleForm: {},
			formName: 'ruleForm',
		}
	},
	methods: {
		show() {
			this.dialogVisible = true
		},
		close() {
			this.dialogVisible = false
		},
		validateAccount(rule, value, callback) {
			console.log('value', value)
			if (!value) {
				callback(new Error('请输入'))
			} else if (!this.$util.validUserAccount(value)) {
				callback(new Error('包含字母、数字或字母数字的组合，长度最多32位'))
			} else {
				callback()
			}
		},
		validatePass(rule, value, callback) {
			console.log('value', value)
			if (!value) {
				callback(new Error('请输入密码'))
			} else if (!this.$util.validPswdComplex(value)) {
				callback(new Error('包含字母、数字或符号，至少2种， 不少于8位'))
			} else {
				if (this.ruleForm.confirmPassword !== '') {
					this.$refs.ruleForm.validateField('confirmPassword')
				}
				callback()
			}
		},
		validateConfirmPass(rule, value, callback) {
			if (!value) {
				callback(new Error('请再次输入密码'))
			} else if (!this.$util.validPswdComplex(value)) {
				callback(new Error('包含字母、数字或符号，至少2种， 不少于8位'))
			} else if (value !== this.ruleForm.password) {
				callback(new Error('两次输入密码不一致!'))
			} else {
				callback()
			}
		},
		handleCancel() {
			this.dialogVisible = false
			this.resetForm()
		},
		submitForm() {
			this.$refs[this.formName].validate(valid => {
				if (valid) {
					const params = {
						...this.ruleForm,
					}
					this.$loading()
					addTeamUser(params).then(
						res => {
							console.log('res', res)
							this.$loading().close()
							this.$message.success('添加成功')
							this.handleCancel()
							this.$emit('finish')
						},
						() => {
							this.$loading().close()
						}
					)
				} else {
					console.log('error submit!!')
					return false
				}
			})
		},
		resetForm() {
			this.$refs[this.formName].resetFields()
		},
	},
}
</script>
